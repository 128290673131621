import React, { useContext } from 'react';
import { Context } from '../../Context';
import { Link, useNavigate } from 'react-router-dom';
import './Footer.css';

  const textOne = [
    { to: '/about-us', text: 'про нас' },
    { to: '/contacts', text: 'контакти' },
    { to: '/sale', text: 'знижки' },
    { to: '/new', text: 'новинки' },
    { to: '/bestsellers', text: 'бестселери' },
  ];

  const textThree = [
    { to: '/clothes', text: 'одяг' },
    { to: '/footwear', text: 'взуття' },
    { to: '/tactical-equipment', text: 'тактичне спорядження' },
    { to: '/ballistic-protection', text: 'балістичний захист' },
    { to: '/equipment', text: 'спорядження екіпірування' },
    { to: '/weapon-accessories', text: 'збройові аксесуари' },
  ];

export default function Footer() {
    const { isAuthenticated, setShowModal } = useContext(Context);

    const navigate = useNavigate();

    const handleClick = () => {
      if (isAuthenticated) {
        navigate('/user');
      } else {
        setShowModal(true);
      }
    };

  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <div className="footer__logo-wrapper">
          <Link to="/" className="footer__logo">
            <img className="img-contain" src="/icon/logo-green.svg" alt="logo" />
          </Link>

          <p className="footer__list-title">Напишіть нам:</p>
          <a href="mailto:zahysnyk.2024@gmail.com" className="footer__list-item-link">
            zahysnyk.2024@gmail.com
          </a>

          <ul className="footer__icons">
            <li className="footer__icons-item">
              <a href="https://www.facebook.com/profile.php?id=61557752902720" target="blank">
                <img className="img-contain" src="/icon/facebook.svg" alt="facebook" />
              </a>
            </li>
            <li className="footer__icons-item">
              <a href="https://www.instagram.com/zahysnik.zp?igsh=MTI0NW03Znhyc21sbA%3D%3D&utm_source=qr" target="blank">
                <img className="img-contain" src="/icon/instagram.svg" alt="instagram" />
              </a>
            </li>
          </ul>
        </div>

        <ul className="footer__list">
          <li className="footer__list-title">про нас</li>

          {textOne.map((item, index) => (
            <li key={index}>
              <Link to={item.to} className="footer__list-item">
                {item.text}
              </Link>
            </li>
          ))}
        </ul>

        <ul className="footer__list">
          <li className="footer__list-title">Допомога</li>
          <li>
            <Link to="/delivery-payment" className="footer__list-item">
              доставка та оплата
            </Link>
          </li>

          <li>
            <button onClick={handleClick} type="button" className="footer__list-item">
              особистий кабінет
            </button>
          </li>
        </ul>

        <ul className="footer__list">
          <li className="footer__list-title">Товари</li>

          {textThree.map((item, index) => (
            <li key={index}>
              <Link to={item.to} className="footer__list-item">
                {item.text}
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <div className="footer__wrapper">
        <div className="footer__wrapper-box">
          <div className="footer__wrapper-box-title">Copyright © 2024 Захисник</div>
          <a className="footer__wrapper-box-title" rel="noreferrer" href="https://circle.in.ua" target="_blank">
            Made by circle.studio
          </a>
        </div>

        <div className="footer__wrapper-box footer__wrapper-box-two">
          <Link to="/privacy-policy" className="footer__wrapper-box-title">
            Політика конфіденційності
          </Link>
          {/* <div className="footer__wrapper-box-title">Terms Of Use</div> */}
        </div>
      </div>
    </footer>
  );
}
