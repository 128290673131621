import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import News from './mainComponents/News';
import { Context } from '../../Context';
import List3item from '../../components/List3item/List3item';
import './Main.css';
import { useMediaQuery } from 'react-responsive';

const category = [
  {
    title: 'одяг',
    src: '/img/pants.png',
    link: '/clothes',
  },
  {
    title: 'взуття',
    src: '/img/shoes.png',
    link: '/footwear',
  },
  {
    title: 'тактичне спорядження',
    src: '/img/gloves.png',
    link: '/tactical-equipment',
  },
  {
    title: 'балістичний захист',
    src: '/img/body-armor.png',
    link: '/ballistic-protection',
  },
  {
    title: 'спорядження та екіпірування',
    src: '/img/helmet.png',
    link: '/equipment',
  },
  {
    title: 'збройові аксесуари',
    src: '/img/bullets.png',
    link: '/weapon-accessories',
  },
];

const random = [
  {
    title: 'ГАРНІТУРА MSA Sordin / Liberator',
    description:
      'Активні навушники LIBERATOR ll призначені для захисту органів слуху від гучних звуків стрілянини, а також забезпечення можливості, у необхідних випадках, чути не гучні звуки, що попереджають, попереджувальні сигнали, звуки обладнання.',
    src: '/img/main.webp',
    link: '/clothes',
  },
];

export default function Main() {
  const { userData } = useContext(Context);
  const [randomNumber, setRandomNumber] = useState(null);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * random.length); // Випадкове число від 0 до 2
    setRandomNumber(randomIndex);
  }, []);

  const isIpad = useMediaQuery({ maxWidth: 1024 });
  const isIphone = useMediaQuery({ maxWidth: 576 });

  return (
    <>
      {randomNumber !== null && (
        <main className="main">
          <section
            className="main__top-container"
            style={{
              background: `linear-gradient(180deg, #222629 0%, rgba(34, 38, 41, 0) 100%), url(${random[randomNumber].src})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              width: '100%',
              height: isIphone ? '100vh' : isIpad ? '70vh' : '100vh',
            }}
          >
            <div className="main__top-container-info">
              <p className="main__top-container-info-text">товар високої якості</p>
              <p className="main__top-container-info-title">{random[randomNumber].title}</p>
              <p className="main__top-container-info-desc">{random[randomNumber].description}</p>
              <Link to={random[randomNumber].link} className="main__btn-color">
                Подивитись детальніше
                <img src="/icon/arrow-right.svg" alt="arrow" className="main__top-container-info-btn-icon" />
              </Link>
            </div>
          </section>
          <section className="main__categoty">
            <ul className="main__categoty-list">
              {category.map((item, index) => {
                return (
                  <li key={index}>
                    <Link to={item.link} className="main__categoty-list-item">
                      <p className="main__categoty-list-item-title">{item.title}</p>
                      <div>
                        <div className="main__categoty-list-item-link">ПОДИВИТИСЬ ВСЕ</div>
                        <div className="main__categoty-list-item-img">
                          <img className="img-contain" src={item.src} alt={item.title} />
                        </div>
                      </div>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </section>
          <div className="main__social">
            <p className="main__social-text">підписуйтесь на наші соціальні мережі</p>
            <a href="https://www.instagram.com/zahysnik.zp?igsh=bHZqd3h0Y2UzOHRu" target="blank" className="main__btn main__btn-color">
            Подивитись Instagram
              <img src="/icon/arrow-right.svg" alt="arrow" className="main__top-container-info-btn-icon" />
            </a>
          </div>
          <List3item title={'наші бестселери'} param={'наші бестселери'} userData={userData} />
          <List3item title={'наші новинки'} param={'new'} userData={userData} />
        </main>
      )}
    </>
  );
}
