import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Container from './Container/Container';
import Main from './Main/Main';
import Category from '../pages/Category/Category';
import Product from './Product/Product';
import Setting from './Admin/Setting/Setting';
import User from './User/User';
import ScrollToTop from '../functions/ScrollToTop';
import Payment from './Payment/Payment';
import NotFound from './NotFound/NotFound';
import Contacts from './Contacts/Contacts';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import FinishOrder from './FinishOrder/FinishOrder';
import AboutUs from './AboutUs/AboutUs';
import DeliveryPayment from './DeliveryPayment/DeliveryPayment';

export default function MainRoute() {
  return (
    <Container>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/:id" element={<Product />} />
        <Route path="/not-found" element={<NotFound />} />

        <Route path="/setting" element={<Setting />} />

        <Route path="/clothes" element={<Category />} />
        <Route path="/footwear" element={<Category />} />
        <Route path="/tactical-equipment" element={<Category />} />
        <Route path="/ballistic-protection" element={<Category />} />
        <Route path="/equipment" element={<Category />} />
        <Route path="/weapon-accessories" element={<Category />} />
        <Route path="/new" element={<Category />} />
        <Route path="/sale" element={<Category />} />
        <Route path="/bestsellers" element={<Category />} />
        <Route path="/user" element={<User />} />
        <Route path="/payment" element={<Payment />} />

        <Route path="/contacts" element={<Contacts />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/delivery-payment" element={<DeliveryPayment />} />

        <Route path="/finish-order" element={<FinishOrder />} />

        <Route path="/about-us" element={<AboutUs />} />
      </Routes>
    </Container>
  );
}
