import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { auth, db } from '../../firebase';
import { onAuthStateChanged, updatePassword, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import { getDoc, doc, updateDoc, setDoc, getDocs, collection } from 'firebase/firestore';
import { Context } from '../../Context';
import UserProductItem from './UserProductItem';
import { Loader } from '../../components/Loader/Loader';
import { Alert, Snackbar } from '@mui/material';
import './User.css';

export default function User() {
  const { userData, setUserData, data, user,  } = useContext(Context);
  const [activeIndex, setActiveIndex] = useState(1);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    secondName: '',
    phone: '',
  });
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [orderList, setOrderList] = useState([]);
  const [favourite, setFavourite] = useState([]);
  const [favouriteList, setFavouriteList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 576 });

  // вспливаючі повідомлення

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMassage, setAlertMessage] = useState(false);
  const [openError, setOpenError] = useState(false);

  const handleAlertClose = () => {
    setOpenAlert(false);
    setOpenError(false);
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (!isAuthenticated) {
  //       setShowModal(true);
  //     } else {
  //       setShowModal(false);
  //     }
  //   }, 2000);
  // }, [isAuthenticated]);

  // для тел актів індекс 0

  useEffect(() => {
    if (isMobile) {
      setActiveIndex(0);
    }
    // eslint-disable-next-line
  }, []);

  // перевіряємо чи залогін і записуємо інфу користувача

  useEffect(() => {
    setIsLoading(true);
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const data = await getUserData(user.uid);
        setUserData(data);
        setOrderList((data.orders || []).reverse());
        setFavourite(data.favourite || []);
        setFormData({
          firstName: data.firstName || '',
          secondName: data.secondName || '',
          phone: data.phone || '',
        });
      } else {
        navigate('/');
      }
    });
    setIsLoading(false);
    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  // порівнюємо фаворіте з датою і шукаємо товари для відображення

  useEffect(() => {
    if (!data) {
      return;
    }
    const filteredItems = data.filter((item) => favourite.includes(item.id));
    setFavouriteList(filteredItems);
  }, [data, favourite]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    setIsLoading(true);
    const user = auth.currentUser;
    if (user) {
      try {
        await setDoc(doc(db, 'users', user.uid), { ...userData, ...formData }, { merge: true });
        setUserData({ ...userData, ...formData });
        setIsEditing(false);
      } catch (error) {
        // console.error('Помилка оновлення даних:', error);
      }
    }
    setAlertMessage('Ваші данні успішно оновлені');
    setOpenAlert(true);

    setIsLoading(false);
  };

  // видаляємо з обраного

  const removeFavourite = async (productId) => {
    setIsLoading(true);
    const frankDocRef = doc(db, 'users', user.uid);

    // Отримуємо поточний документ користувача
    const docSnapshot = await getDoc(frankDocRef);

    if (docSnapshot.exists()) {
      // Отримуємо поточний список обраних товарів
      const currentFavourites = docSnapshot.data().favourite || [];

      // Перевіряємо, чи є productId в списку обраних товарів
      if (currentFavourites.includes(productId)) {
        // Видаляємо productId з поточного списку обраних товарів
        const updatedFavourites = currentFavourites.filter((id) => id !== productId);

        // Оновлюємо документ користувача з оновленим списком обраних товарів
        await updateDoc(frankDocRef, {
          favourite: updatedFavourites,
        });

        const filteredItems = data.filter((item) => updatedFavourites.includes(item.id));
        setFavouriteList(filteredItems);

        setAlertMessage('Видалено');
        setOpenError(true);
      } else {
        setAlertMessage('Виникла помилка видалення. Перезавантажте та спробуйте ще раз');
        setOpenError(true);
      }
    }
    setIsLoading(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePasswordInputChange = (e) => {
    const { name, value } = e.target;
    setPasswordData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePasswordChange = async () => {
    const user = auth.currentUser;
    setIsLoading(true);
    if (passwordData.newPassword === '') {
      setAlertMessage('Перед зміною паролю заповніть поля');
      setOpenError(true);
      setIsLoading(false);
      return;
    }
    if (passwordData.newPassword.length < 6) {
      setAlertMessage('Новий пароль має бут мінімум з 6 символів');
      setPasswordData({ ...passwordData, newPassword: '', confirmPassword: '' });
      setOpenError(true);
      setIsLoading(false);
      return;
    }

    if (user && passwordData.newPassword === passwordData.confirmPassword) {
      try {
        const credential = EmailAuthProvider.credential(user.email, passwordData.currentPassword);
        await reauthenticateWithCredential(user, credential);
        await updatePassword(user, passwordData.newPassword);
        setPasswordData({ currentPassword: '', newPassword: '', confirmPassword: '' });
        setAlertMessage('Пароль успішно змінений');
        setOpenAlert(true);
      } catch (error) {
        setAlertMessage('Ви ввели неправильно поточний пароль');
        setOpenError(true);
      }
    } else {
      setAlertMessage('Ваші нові паролі не співпадають');
      setOpenError(true);
    }

    setIsLoading(false);
  };

  const getUserData = async (uid) => {
    setIsLoading(true);
    try {
      const userDoc = await getDoc(doc(db, 'users', uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setIsLoading(false);
        return userData;
      } else {
        setIsLoading(false);
        return null;
      }
    } catch (error) {
      // console.error('Помилка отримання даних користувача:', error);
      setIsLoading(false);
      return null;
    }
  };

  const testData = async () => {
    setIsLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, 'users'));
      if (!querySnapshot.empty) {
        const newData = [];
        querySnapshot.forEach((doc) => {
          newData.push({ id: doc.id, ...doc.data() });
        });
      }
    } catch (error) {
      console.error('Помилка отримання даних користувача:', error);
      return null;
    }
    setIsLoading(false);
  };

  useEffect(() => {
    testData();
  }, []);

  const handleChangeIndex = (index) => {
    setActiveIndex(index);
    if (isMobile && activeIndex === index) {
      setActiveIndex(0);
    }
    window.scrollTo(0, 0);
  };

  const singOut = () => {
    setIsLoading(true);
    setUserData(null);
    auth.signOut();
    navigate('/');
    setIsLoading(false);
  };

  const navList = [
    { item: 'Мій профіль', img: '/icon/user.svg', index: 1 },
    { item: 'Мої замовлення', img: '/icon/checklists.svg', index: 2 },
    { item: 'Обране', img: '/icon/favorite.svg', index: 3 },
  ];

  const formFields = [
    { label: "Ім'я", name: 'firstName' },
    { label: 'Прізвище', name: 'secondName' },
    { label: 'Телефон', name: 'phone' },
  ];

  const passwordFields = [
    { label: 'Поточний пароль', name: 'currentPassword' },
    { label: 'Новий пароль', name: 'newPassword' },
    { label: 'Підтвердіть новий пароль', name: 'confirmPassword' },
  ];
  // success;
  return (
    <>
      {isLoading && <Loader />}
      <Snackbar open={openAlert} autoHideDuration={2000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert onClose={handleAlertClose} severity="success" variant="filled" sx={{ width: '100%', backgroundColor: '#61892f' }}>
          {alertMassage}
        </Alert>
      </Snackbar>
      <Snackbar open={openError} autoHideDuration={2000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert onClose={handleAlertClose} severity="error" variant="filled" sx={{ width: '100%', backgroundColor: '#61892f' }}>
          {alertMassage}
        </Alert>
      </Snackbar>
      {userData && (
        <div className="user">
          <div className="user__nav">
            <div className="user__nav-profile" onClick={() => handleChangeIndex(1)}>
              <div className="user__nav-profile-icon">
                <img src="/icon/user-profile.svg" alt="profile" />
              </div>
              <div className="user__nav-profile-info">
                <p className="user__nav-profile-info-name">{userData.firstName}</p>
                <p className="user__nav-profile-info-email">{userData.email}</p>
              </div>
            </div>
            <ul className="user__nav-profile-list">
              {navList.map(({ item, img, index }) => {
                return (
                  <li key={index} className="user__nav-profile-list-item-wrap">
                    <div className="user__nav-profile-list-item" onClick={() => handleChangeIndex(index)}>
                      <div className="user__nav-profile-list-item-icon">
                        <img src={img} alt={item} />
                      </div>
                      <p className="user__nav-profile-list-item-title">{item}</p>
                      {isMobile && (
                        <div className={`user__nav-profile-list-item-arrow ${activeIndex === index && 'user-arrow--rotated'}`}>
                          <img src="/icon/user-arrow.svg" alt="arrow" />
                        </div>
                      )}
                    </div>
                    {index === 1 && activeIndex === 1 && isMobile && (
                      <div className="user__info--mobile-wrap">
                        <div className="user__info">
                          <form className="user__info-form">
                            {formFields.map((field) => (
                              <input
                                className="user__info-form-input"
                                key={field.name}
                                type="text"
                                name={field.name}
                                value={formData[field.name]}
                                onChange={handleChange}
                                disabled={!isEditing}
                                placeholder={field.label}
                              />
                            ))}
                          </form>
                          {isEditing ? (
                            <button onClick={handleSaveClick} className="user__info-btn">
                              Зберегти
                            </button>
                          ) : (
                            <button onClick={handleEditClick} className="user__info-btn">
                              Редагувати
                            </button>
                          )}
                        </div>
                        <div className="user__info-pass-wrap">
                          <form className="user__info-form">
                            {passwordFields.map((field) => (
                              <input
                                key={field.name}
                                className="user__info-form-input"
                                type="password"
                                name={field.name}
                                value={passwordData[field.name]}
                                onChange={handlePasswordInputChange}
                                placeholder={field.label}
                              />
                            ))}
                          </form>
                          <button className="user__info-btn" onClick={handlePasswordChange}>
                            Зберегти пароль
                          </button>
                        </div>
                      </div>
                    )}
                    {index === 2 &&
                      activeIndex === 2 &&
                      isMobile &&
                      (orderList ? (
                        <ul className="user__element-item-list">
                          {orderList.map((order) => (
                            <UserProductItem order={order} key={order.orderId} devList={true} />
                          ))}
                        </ul>
                      ) : (
                        <p className="user__element-item-list-text--empty">Ваша історія замовлень пуста</p>
                      ))}
                    {index === 3 && activeIndex === 3 && isMobile && (
                      <ul className="user__element-item-list">
                        {favouriteList && favouriteList.length > 0 ? (
                          favouriteList.map((favorite) => {
                            return <UserProductItem order={favorite} key={favorite.id} devList={false} onRemove={removeFavourite} />;
                          })
                        ) : (
                          <p className="user__element-item-list-text--empty">Ваш список товарів пустий</p>
                        )}
                      </ul>
                    )}
                  </li>
                );
              })}
            </ul>
            <button className="user__nav-profile-logOut-button" onClick={() => singOut()}>
              вийти
            </button>
          </div>
          {!isMobile && (
            <div className="user__element">
              {activeIndex === 1 && (
                <>
                  <div className="user__info">
                    <p className="user__info-title">Ваші контактні данні</p>
                    <form className="user__info-form">
                      {formFields.map((field) => (
                        <input
                          className="user__info-form-input"
                          key={field.name}
                          type="text"
                          name={field.name}
                          value={formData[field.name]}
                          onChange={handleChange}
                          disabled={!isEditing}
                          placeholder={field.label}
                        />
                      ))}
                    </form>
                    {isEditing ? (
                      <button onClick={handleSaveClick} className="user__info-btn">
                        Зберегти
                      </button>
                    ) : (
                      <button onClick={handleEditClick} className="user__info-btn">
                        Редагувати
                      </button>
                    )}
                  </div>
                  <div className="user__info-pass-wrap">
                    <p className="user__info-title">Змінити пароль</p>
                    <form className="user__info-form">
                      {passwordFields.map((field) => (
                        <input
                          key={field.name}
                          className="user__info-form-input"
                          type="password"
                          name={field.name}
                          value={passwordData[field.name]}
                          onChange={handlePasswordInputChange}
                          placeholder={field.label}
                        />
                      ))}
                    </form>
                    <button className="user__info-btn" onClick={handlePasswordChange}>
                      Зберегти пароль
                    </button>
                  </div>
                </>
              )}
              {activeIndex === 2 && (
                <>
                  <p className="user__element-item-title">Історія замовлень</p>
                  {orderList ? (
                    <ul className="user__element-item-list">
                      {orderList.map((order) => (
                        <UserProductItem order={order} key={order.orderId} devList={true} />
                      ))}
                    </ul>
                  ) : (
                    <p className="user__element-item-list-text--empty">Ваша історія замовлень пуста</p>
                  )}
                </>
              )}

              {activeIndex === 3 && (
                <>
                  <p className="user__element-item-title">Обране</p>
                  <ul className="user__element-item-list">
                    {favouriteList && favouriteList.length > 0 ? (
                      favouriteList.map((favorite) => {
                        return <UserProductItem order={favorite} key={favorite.id} devList={false} onRemove={removeFavourite} />;
                      })
                    ) : (
                      <p className="user__element-item-list-text--empty">Ваш список товарів пустий</p>
                    )}
                  </ul>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}
