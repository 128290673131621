import React, { useContext, useEffect, useState } from 'react';
import { categoryArray, collectionArray, inputFields, inputSale, selectFields, textAreaFields, checkboxes, newSale, colors, subcategoriesArray } from './Setting-info';
import { Context } from '../../../Context';
import { db } from '../../../firebase';
import { setDoc, doc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { getStorage, ref, deleteObject } from 'firebase/storage';
import Snackbar from '@mui/material/Snackbar';

import { addPhoto, getId } from './helper';
import { Loader } from '../../../components/Loader/Loader';

import RequireAdminAuth from '../RequireAdminAuth';
import { filter } from '../../../functions/Filter';
import TextField from '@mui/material/TextField';
import { Link as ScrollLink } from 'react-scroll';
import { useMediaQuery } from 'react-responsive';
import Comments from './Comments/Comments';
import Sales from './Sales/Sales';
import { Alert } from '@mui/material';
import { Link } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import { isVideo } from '../../../functions/IsVideo';
import './Setting.css';

export default function Setting() {
  const { data, setData } = useContext(Context);
  const [dataFilter, setDataFilter] = useState([]); //фільтрована дата
  const [arrayPhoto, setArrayPhoto] = useState([]); //масив фотографій з поля інпут
  const [arrayDeletePhoto, setArrayDeletePhoto] = useState([]); //масив фотографій для видалення з сховища
  const [product, setProduct] = useState(false); // один товар, формується при заповненні інпутів
  const [flagOpenForm, setFlagOpenForm] = useState(false); // флаг, який контролює відкривання форми заповнення товару
  const [editItem, setEditItem] = useState(null); // записуємо товар який передається в поля інпутів
  const [isLoading, setIsLoading] = useState(false); // стан лоадера
  const [copyFlag, setCopyFlag] = useState(false); // флаг якеий дозволяє зробити копію в кнопці сабміт форми

  const [flag, setFlag] = useState('main'); // флаг переключает окна

  const storage = getStorage();

  const isIpad = useMediaQuery({ maxWidth: 1024 });

  // ------------------------------- прокручування вгору -------------------------------

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // ------------------------------- для фільтрів -------------------------------

  const [filters, setFilters] = useState({
    selectedCategory: false,
    selectedCollection: false,
    searchItem: false,
    searchColor: false,
    searchSet: false,
    searchNewSale: false,
    showBestsellers: false,
    showHide: false,
  });

  // ------------------------------- повідомленя -------------------------------

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMassage, setAlertMassage] = useState(false);

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  // ------------------------------- створення фільтрованої -------------------------------

  useEffect(() => {
    if (data) {
      setDataFilter(data);
    }
  }, [data]);

  // ------------------------------- оновлення дати з контексту -------------------------------

  const updateData = (product, id) => {
    const updatedValues = { ...product, id };
    // Добавление нового элемента в начало массива
    setData((prevData) => [updatedValues, ...prevData]);
  };

  // ------------------------------- додавання товару -------------------------------
  // addItem для того щоб прокидувати товар який копіюємо
  const addProduct = async (addItem) => {
    setIsLoading(true);
    handleScrollToTop();
    try {
      const imageUrls = await Promise.all(
        arrayPhoto.map(async (image) => {
          const uniqueName = `${Math.random().toString(36).substring(2, 10)}-${image.name}`;
          const imageUrl = await addPhoto(uniqueName, image);
          return imageUrl;
        }),
      );

      let newProduct = { ...addItem, img: imageUrls, createdAt: serverTimestamp()};

      const newID = getId(product.title ? product.title : editItem.title);

      await setDoc(doc(db, 'data', newID), newProduct);
      updateData(newProduct, newID);
      setAlertMassage('Додано');
      setOpenAlert(true);
    } catch (error) {
      console.error('Error adding product:', error);
    }
    setIsLoading(false);
    setFilters({
      selectedCategory: false,
      selectedCollection: false,
      searchItem: false,
      searchColor: false,
      searchSet: false,
      searchNewSale: false,
      showBestsellers: false,
      showHide: false,
    });
  };

  // ------------------------------- копіювання товару -------------------------------

  const copyProduct = async (item) => {
    //прокидуємо обраний товар в едіт айтем, але забираємо в нього імдж і ід
    setIsLoading(true);
    const { img, id, ...otherItem } = item;
    const newProduct = { ...otherItem, img: [] };
    setEditItem(newProduct);
    setFlagOpenForm(true);
    setCopyFlag(true);
    setIsLoading(false);
  };

  // -------------------------------  редагування товару -------------------------------
  const changeProduct = async (product) => {
    handleScrollToTop();
    setIsLoading(true);
    // видалення фотографій (якщо є)
    if (arrayDeletePhoto.length > 0) {
      const deleteImgPromises = arrayDeletePhoto.map((img) => deletePhoto(img));
      await Promise.all(deleteImgPromises);
    }
    // додавання нових фотографій з поля вводу (якщо є)
    if (arrayPhoto.length > 0) {
      const imageUrls = await Promise.all(
        arrayPhoto.map(async (image) => {
          const uniqueName = `${Math.random().toString(36).substring(2, 10)}-${image.name}`;
          const imageUrl = await addPhoto(uniqueName, image);
          return imageUrl;
        }),
      );
      // робимо новий масив з фотками
      const newImgProduct = { ...product, img: [...product.img, ...imageUrls] };
      // пушим на фаербейс
      await setDoc(doc(db, 'data', newImgProduct.id), newImgProduct);
      setAlertMassage('Оновлено');
      setOpenAlert(true);
      const index = data.findIndex((item) => item.id === product.id);
      if (index !== -1) {
        const updatedData = [...data];
        updatedData[index] = newImgProduct;
        setData(updatedData);
      }
      setAlertMassage('Оновлено');
      setOpenAlert(true);
    } else {
      // якщо немає нових фотографій, пушим на фаербейс
      await setDoc(doc(db, 'data', product.id), product);
      // оновлюємо дату
      const index = data.findIndex((item) => item.id === product.id);
      if (index !== -1) {
        const updatedData = [...data];
        updatedData[index] = product;
        setData(updatedData);
      }
      setAlertMassage('Оновлено');
      setOpenAlert(true);
    }
    setIsLoading(false);
    setFilters({
      selectedCategory: false,
      selectedCollection: false,
      searchItem: false,
      searchColor: false,
      searchSet: false,
      searchNewSale: false,
      showBestsellers: false,
      showHide: false,
    });
  };

  //-------------------------------- видалення товару---------------------------------------

  const deleteProduct = async (id, imgArr) => {
    setIsLoading(true);
    // оновлюємо дату
    const index = data.findIndex((item) => item.id === id);
    if (index !== -1) {
      const updatedData = [...data];
      updatedData.splice(index, 1);
      setData(updatedData);
    }
    try {
      const deleteImgPromises = imgArr.map((img) => deletePhoto(img));
      await Promise.all(deleteImgPromises);
      await deleteDoc(doc(db, 'data', id));
      setAlertMassage('Видалено');
      setOpenAlert(true);
    } catch (error) {
      console.error('Error deleting product:', error);
    }
    setIsLoading(false);
    setFilters({
      selectedCategory: false,
      selectedCollection: false,
      searchItem: false,
      searchColor: false,
      searchSet: false,
      searchNewSale: false,
      showBestsellers: false,
      showHide: false,
    });
  };

  // ------------------------------- додавання фото -------------------------------
  const handleFileDownload = async (e) => {
    if (e.target.files.length > 0) {
      const filesArray = Array.from(e.target.files);

      // Поддерживаемые форматы для сжатия
      const imageFormats = ['image/jpeg', 'image/png'];

      // Функция для создания нового файла с изменённым расширением
      const renameFile = (file, newExtension) => {
        const newName = file.name.replace(/\.[^/.]+$/, `.${newExtension}`);
        return new File([file], newName, { type: `image/${newExtension}` });
      };

      // Настройки сжатия
      const options = {
        maxSizeMB: 0.5, // Максимальный размер файла в мегабайтах
        maxWidthOrHeight: 1920, // Максимальная ширина или высота изображения
        useWebWorker: true, // Использовать Web Worker для повышения производительности
        fileType: 'image/webp', // Указываем формат WebP
        initialQuality: 0.6,
      };

      // Сжимаем или пропускаем каждое изображение
      const processedFiles = await Promise.all(
        filesArray.map(async (file) => {
          if (imageFormats.includes(file.type)) {
            // Если файл изображение, сжимаем его
            try {
              const compressedBlob = await imageCompression(file, options);
              // Создаём новый файл с обновлённым расширением
              const compressedFile = renameFile(file, 'webp');
              return new File([compressedBlob], compressedFile.name, { type: 'image/webp' });
            } catch (error) {
              console.error('Error compressing image:', error);
              return file; // Если сжатие не удалось, возвращаем оригинальный файл
            }
          } else {
            // Если файл видео или другой формат, пропускаем сжатие
            return file;
          }
        }),
      );

      // Устанавливаем массив обработанных файлов
      setArrayPhoto(processedFiles);
    }
  };


  // ------------------------------- видалення 1 фото -------------------------------

  const deletePhoto = async (imgURL) => {
    const fileRef = ref(storage, imgURL);

    try {
      await deleteObject(fileRef);
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  //  ---------------------- видалення 1 фото з товару який редагуємо -------------------------

  const deletePhotoLocal = (url) => {
    if (!editItem || !editItem.img) {
      console.error('editItem або editItem.img не існує');
      return;
    }
    const imgArr = [...editItem.img];
    const newArray = imgArr.filter((img) => img !== url);
    setEditItem({
      ...editItem,
      img: newArray,
    });
    setArrayDeletePhoto([...arrayDeletePhoto, url]);
  };

  // -------------------------------  запис інпутів -------------------------------
  const handleInputChange = (event, editItem) => {
    const { name, value, type, checked, options, multiple } = event.target;

    let newValue;
    if (type === 'checkbox') {
      newValue = checked;
    } else if (multiple) {
      newValue = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);
    } else {
      newValue = value;
    }

    if (editItem) {
      if (name === 'newSale' && value !== 'sale') {
        setEditItem({ ...editItem, [name]: newValue, priceSale: '' });
      } else {
        setEditItem({ ...editItem, [name]: newValue });
      }
    } else {
      setProduct({
        ...product,
        [name]: newValue,
      });
    }
  };

  // -------------------------------  кнопки для переміщення зображень -------------------------------
  const handleMoveUpElement = (index) => {
    const targetIndex = index + 1;
    const newItems = [...editItem.img]; // Копіюємо масив елементів
    const elementToMove = newItems.splice(index, 1)[0]; // Видаляємо елемент з поточного індексу
    newItems.splice(targetIndex, 0, elementToMove); // Вставляємо елемент на нову позицію
    setEditItem({ ...editItem, img: newItems }); // Оновлюємо стан з новим масивом
  };

  const handleMoveDownElement = (index) => {
    const targetIndex = index - 1;
    const newItems = [...editItem.img]; // Копіюємо масив елементів
    const elementToMove = newItems.splice(index, 1)[0]; // Видаляємо елемент з поточного індексу
    newItems.splice(targetIndex, 0, elementToMove); // Вставляємо елемент на нову позицію
    setEditItem({ ...editItem, img: newItems }); // Оновлюємо стан з новим масивом
  };
  // ------------------------------- фільтр -------------------------------

  // функция изменений для фильра
  const handleChangeFilter = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  // Фильтр
  useEffect(() => {
    const { selectedCategory, selectedCollection, searchNewSale, searchItem, showBestsellers, showHide } = filters;
    const updatedFilters = {
      collection: selectedCollection === 'Всі категорії' ? false : selectedCollection,
      category: selectedCategory === 'Всі підкатегорії' ? false : selectedCategory,
      newSale: searchNewSale === 'false' ? false : searchNewSale,
      title: searchItem === '' ? false : searchItem,
      bestsellers: showBestsellers,
      hide: showHide,
    };

    let filteredProducts = data;

    if (data) {
      const newFilteredData = filter(filteredProducts, updatedFilters);
      let visibleProducts = newFilteredData; // По умолчанию устанавливаем исходные данные

      if (!filters.showHide) {
        visibleProducts = newFilteredData.filter((product) => !product.hide);
      }

      setDataFilter(visibleProducts);
    }
    // eslint-disable-next-line
  }, [filters]);

  // показываем корректные наименования
  const getTranslation = (value, array, boolin) => {
    const item = array.find((item) => item.value === value);
    if (boolin) {
      return item ? item.label.ukr : '';
    } else {
      return item ? item.label : '';
    }
  };

  return (
    <RequireAdminAuth>
      {isLoading && <Loader />}
      <div className="setting" id="items">
        <Snackbar open={openAlert} autoHideDuration={2000} onClose={handleAlertClose}>
          <Alert onClose={handleAlertClose} severity="success" variant="filled" sx={{ width: '100%' }}>
            {alertMassage}
          </Alert>
        </Snackbar>
        <div className="setting__button">
          <button
            className="setting__button-title"
            onClick={() => {
              setProduct(false);
              setEditItem(null);
              setArrayPhoto([]);
              setArrayDeletePhoto([]);
              setFlagOpenForm(!flagOpenForm);
              setCopyFlag(false);
              setFlag('main');
            }}
          >
            {!flagOpenForm ? 'Додати товар' : 'Відміна'}
          </button>

          <button
            onClick={() => {
              setFlagOpenForm(false);
              setFlag('comments');
            }}
            className="setting__button-title"
          >
            Коментарі
          </button>

          <button
            onClick={() => {
              setFlagOpenForm(false);
              setFlag('sale');
            }}
            className="setting__button-title"
          >
            ОПТ
          </button>
        </div>
        {flagOpenForm && flag === 'main' && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (editItem && !copyFlag) {
                changeProduct(editItem);
              } else if (!product && copyFlag) {
                addProduct(editItem);
              } else {
                addProduct(product);
              }
              setProduct(false);
              setEditItem(null);
              setFlagOpenForm(false);
              setArrayPhoto([]);
              setArrayDeletePhoto([]);
              setCopyFlag(false);
            }}
          >
            <div className="setting__inputs-window">
              <div className="setting__inputs">
                <input className="setting__inputs-file" onChange={handleFileDownload} type="file" multiple required={!editItem} />

                {inputFields.map((field) => (
                  <TextField
                    key={field.name}
                    name={field.name}
                    onChange={(e) => handleInputChange(e, editItem)}
                    value={editItem ? editItem[field.name] : product.name}
                    type="text"
                    variant="outlined"
                    required={field.req}
                    label={field.placeholder}
                    placeholder="*"
                    InputLabelProps={{
                      shrink: true,
                      style: { color: 'black', fontSize: '14px', fontFamily: 'Gravity' },
                    }}
                    inputProps={{
                      sx: { color: 'black' },
                      style: {
                        borderColor: 'black',
                        fontSize: '15px',
                        fontFamily: 'Gravity',
                      },
                      onKeyPress: (event) => {
                        // Предотвращаем ввод, если это не число или не разрешенный символ
                        if (field.type === 'number') {
                          const allowedCharacters = /^\d+$/; // Разрешены только цифры
                          if (!allowedCharacters.test(event.key)) {
                            event.preventDefault();
                          }
                        }
                      },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': { borderColor: 'black' },
                      },
                    }}
                  />
                ))}

                {selectFields.map((field) => (
                  <select
                    multiple={field.multiple}
                    key={field.name}
                    className={`setting__inputs-select ${field.multiple ? 'setting__inputs-select-big' : ''}`}
                    name={field.name}
                    onChange={(e) => handleInputChange(e, editItem)}
                    value={editItem ? editItem[field.name] : product.name}
                    required={field.req}
                  >
                    <option value="" hidden>
                      {field.placeholder}
                    </option>
                    {field.options.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                ))}

                {(product && product.newSale === 'sale') || (editItem && editItem.newSale === 'sale')
                  ? inputSale.map((field) => (
                      <TextField
                        key={field.name}
                        name={field.name}
                        onChange={(e) => handleInputChange(e, editItem)}
                        value={editItem ? editItem[field.name] : product.name}
                        type="numder"
                        required
                        variant="outlined"
                        label={field.placeholder}
                        placeholder="++"
                        InputLabelProps={{
                          shrink: true,
                          style: { color: 'black', fontSize: '14px', fontFamily: 'Gravity' },
                        }}
                        inputProps={{
                          sx: { color: 'black' },
                          style: {
                            borderColor: 'black',
                            fontSize: '15px',
                            fontFamily: 'Gravity',
                          },
                          onKeyPress: (event) => {
                            // Предотвращаем ввод, если это не число или не разрешенный символ
                            if (field.type === 'number') {
                              const allowedCharacters = /^\d+$/; // Разрешены только цифры
                              if (!allowedCharacters.test(event.key)) {
                                event.preventDefault();
                              }
                            }
                          },
                        }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': { borderColor: 'black' },
                          },
                        }}
                      />
                    ))
                  : null}

                {checkboxes.map((setting) => (
                  <label key={setting.name} className="setting__inputs-select-checkbox">
                    <input
                      name={setting.name}
                      type="checkbox"
                      onChange={(e) => handleInputChange(e, editItem)}
                      value={editItem ? editItem[setting.name] : product.name}
                      checked={editItem ? editItem[setting.name] : product[setting.name]}
                    />
                    {setting.label}
                  </label>
                ))}

                <button className="setting__button-title" type="submit">
                  Зберегти
                </button>
              </div>

              {/* фотки */}
              {editItem && (
                <div className="setting__inputs-foto">
                  {editItem.img.map((url, index) => (
                    <div key={index} className="setting__inputs-foto-inner">
                      <div className="setting__inputs-foto-wrapper">
                        <img src={!isVideo(url) ? '/icon/video.png' : url} alt="small foto" />
                        <div
                          onClick={() => {
                            deletePhotoLocal(url);
                          }}
                          className="setting__inputs-foto-delete"
                        >
                          x
                        </div>
                      </div>
                      <div className="setting__inputs-foto-arrow-wrap">
                        <button
                          type="button"
                          className="setting__inputs-foto-arrow setting__inputs-foto-arrow--up"
                          onClick={() => {
                            handleMoveDownElement(index);
                          }}
                        >
                          <img src="/icon/arrow.svg" alt="arrow" className="img-contain" />
                        </button>

                        <button
                          type="button"
                          className="setting__inputs-foto-arrow"
                          onClick={() => {
                            handleMoveUpElement(index);
                          }}
                        >
                          <img src="/icon/arrow.svg" alt="arrow" className="img-contain" />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              <div className="setting__inputs">
                {textAreaFields.map((field) => (
                  <TextField
                    placeholder={'*'}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: 'black', fontSize: '14px', fontFamily: 'Gravity' },
                    }}
                    key={field.name}
                    required
                    name={field.name}
                    label={field.placeholder}
                    onChange={(e) => handleInputChange(e, editItem)}
                    value={editItem ? editItem[field.name] : product.name}
                    variant="outlined"
                    multiline
                    rows={16}
                    inputProps={{
                      sx: { color: 'black' },
                      style: {
                        borderColor: 'black',
                        fontSize: '15px',
                        fontFamily: 'Gravity',
                      },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': { borderColor: 'black' },
                      },
                    }}
                  />
                ))}
              </div>
            </div>
          </form>
        )}

        {flag === 'main' && (
          <div className="setting__search">
            <select className="setting__search-select" value={filters.selectedCollection} onChange={(e) => handleChangeFilter('selectedCollection', e.target.value)}>
              <option>Всі категорії</option>
              {collectionArray.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>

            <select className="setting__search-select" value={filters.selectedCategory} onChange={(e) => handleChangeFilter('selectedCategory', e.target.value)}>
              <option>Всі підкатегорії</option>
              {categoryArray.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>

            <select className="setting__search-select" value={filters.searchNewSale} onChange={(e) => handleChangeFilter('searchNewSale', e.target.value)}>
              {newSale.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>

            <input
              value={filters.searchItem || ''}
              className="setting__search-select"
              onChange={(e) => handleChangeFilter('searchItem', e.target.value)}
              type="text"
              name="search"
              placeholder="Пошук товару за ім`ям"
            />

            {/* <input
              value={filters.searchColor}
              className="setting__search-select"
              onChange={(e) => handleChangeFilter('searchColor', e.target.value)}
              type="number"
              name="color"
              placeholder="номер кольору"
            /> */}
            {/* <input
              value={filters.searchSet}
              className="setting__search-select"
              onChange={(e) => handleChangeFilter('searchSet', e.target.value)}
              type="number"
              name="set"
              placeholder="номер комплекту"
            /> */}

            <label className="setting__search-select-change">
              <input value={filters.showBestsellers} name="bestsellers" type="checkbox" onChange={(e) => handleChangeFilter('showBestsellers', e.target.checked)} />
              Bestsellers
            </label>
            <label className="setting__search-select-change">
              <input value={filters.showHide} name="hide" type="checkbox" onChange={(e) => handleChangeFilter('showHide', e.target.checked)} />
              Сховані товари
            </label>
          </div>
        )}

        {flag === 'main' && (
          <div className="setting__item-wrapper">
            {data &&
              dataFilter
                .map((item, index) => (
                  <div key={index} className="setting__item">
                    <div className="setting__item-info">
                      <Link to={`/${item.id}`} className="setting__item-info-picture">
                        <img src={item.img} alt={item.img} />
                      </Link>
                      <div className="setting__item-info-wrapper">
                        <div className="setting__item-info-title">{item.title}</div>
                        <div className="setting__item-info-numbers">Ціна: {item.price}</div>
                        <div className="setting__item-info-numbers">{getTranslation(item.color, colors, false)}</div>
                        {!isIpad && <div className="setting__item-info-others">Категорія: {getTranslation(item.collection, collectionArray, false)}</div>}
                        {!isIpad && <div className="setting__item-info-others">Під 1: {getTranslation(item.category, categoryArray, false)}</div>}
                        {!isIpad && <div className="setting__item-info-others">Під 2: {getTranslation(item.subCategories, subcategoriesArray, false)}</div>}
                      </div>
                    </div>
                    <button
                      className="setting__item-edit"
                      onClick={() => {
                        const isConfirmed = window.confirm('Ви впевнені, що хочете зробити копію цього товару?');
                        if (isConfirmed) {
                          handleScrollToTop();
                          copyProduct(item);
                        }
                      }}
                    >
                      C
                    </button>
                    <ScrollLink
                      to="items"
                      offset={-100}
                      smooth={true}
                      duration={300}
                      className="setting__item-edit"
                      onClick={() => {
                        setEditItem(item);
                        setFlagOpenForm(true);
                      }}
                    >
                      E
                    </ScrollLink>
                    <button
                      className="setting__item-delete"
                      onClick={() => {
                        const isConfirmed = window.confirm('Ви впевнені, що хочете видалити цей товар?');
                        if (isConfirmed) {
                          deleteProduct(item.id, item.img);
                        }
                      }}
                    >
                      X
                    </button>
                  </div>
                ))}
          </div>
        )}

        {flag === 'comments' && <Comments />}
        {flag === 'sale' && <Sales alert={setAlertMassage} open={setOpenAlert} />}
      </div>
    </RequireAdminAuth>
  );
}
