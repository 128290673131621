import React from 'react';
import { getTranslation } from '../../../functions/Translation';
import { colors } from '../../Admin/Setting/Setting-info';
import './PaymentProductList.css';

export default function PaymentProductList({ itemList, userData }) {
  return (
    <ul className="payment-product">
      {itemList &&
        itemList.map((item, index) => {
          return (
            <li className="payment-product-item" key={index}>
              <div className="payment-product-item-img">
                <img src={item.img[0]} alt={item.title} />
              </div>
              <div className="payment-product-item-info">
                <div>
                  <p className="payment-product-item-info-title">{item.title}</p>
                  <p className="payment-product-item-info-manufacturer">{item.category}</p>
                </div>
                <div className="payment-product-item-info-bottom">
                  <ul className="payment-product-item-info-bottom-list">
                    <li className="payment-product-item-info-bottom-list-item">К-сть: {item.quantity}</li>
                    <li className="payment-product-item-info-bottom-list-item">Колір: {getTranslation(colors, item.color)}</li>
                    {item.size && <li className="payment-product-item-info-bottom-list-item">Розмір: {item.size}</li>}
                  </ul>
                  <div className="payment-product-item-info-bottom-price--wrap">
                    {item.priceSale && !userData?.opt && item.priceSale !== '' ? (
                      <>
                        <span className="payment-product-item-info-bottom-price--sale">{item.quantity * item.price} грн</span>
                        {Math.floor(item.price * (1 - item.priceSale / 100)) * item.quantity} грн
                      </>
                    ) : userData?.opt ? (
                      <>
                        ₴ {Math.floor(item.price * (1 - userData.opt / 100)) * item.quantity}
                        <span className="item__price-sale">₴ {item.quantity * item.price}</span>
                      </>
                    ) : (
                      <>{item.quantity * item.price} грн</>
                    )}
                  </div>
                </div>
              </div>
            </li>
          );
        })}
    </ul>
  );
}
