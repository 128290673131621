import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../Context';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import UserModal from '../UserModal/UserModal';
import { text, menuLinks } from './header-text';
import './Header.css';

export default function Header() {
  const { busketNumber, setActiveWindow, activeWindow, isAuthenticated, showModal, setShowModal, userData, user } = useContext(Context);
  const [logo, setLogo] = useState(false);

  const location = useLocation();

  const navigate = useNavigate();

  const handleClick = () => {
    if (isAuthenticated && user.emailVerified) {
      navigate('/user');
    } else {
      setShowModal(true);
    }
  };

  const handleBurger = () => {
    if (activeWindow) {
      setActiveWindow(false);
    } else {
      setActiveWindow('burger');
    }
  };

  useEffect(() => {
    if (location.pathname !== '/') {
      setLogo(true);
    } else {
      setLogo(false);
    }
  }, [location]);

  return (
    <header className={`header ${logo ? 'header-relative' : ''} `}>
      {showModal && <UserModal onClose={() => setShowModal(false)} />}
      <div className="header__wrapper">
        <a href="/" className="header__logo">
          <img className="img-contain" src={!logo ? '/icon/logo.svg' : '/icon/logo-green.svg'} alt="logo" />
        </a>
        <ul className="header__menu">
          <li className="header__menu-list">
            <button
              className="header__menu-item"
              type="button"
              onClick={() => {
                if (!activeWindow) {
                  setActiveWindow('catalog');
                } else {
                  setActiveWindow(false);
                }
              }}
            >
              каталог
            </button>
            <ul className={`header__menu-list-item ${activeWindow === 'catalog' ? 'header__menu-list-item-active' : ''}`}>
              {menuLinks.map((item, index) => (
                <li key={index}>
                  <Link onClick={() => setActiveWindow(false)} to={item.to}>
                    {item.text}
                  </Link>
                </li>
              ))}
            </ul>
          </li>

          {text.map((item, index) => (
            <li key={index}>
              <Link to={item.to} onClick={() => setActiveWindow(false)} className="header__menu-item">
                {item.text}
              </Link>
            </li>
          ))}
        </ul>

        <ul className="header__icons">
          <li className="header__icons-wrapper">
            {userData && isAuthenticated && user.emailVerified && (
              <button type="button" className="header__icons-info" onClick={handleClick}>
                {userData.firstName}
              </button>
            )}

            <button className={isAuthenticated && user.emailVerified ? 'header__icons-item-active' : 'header__icons-item'} type="button" onClick={handleClick}>
              <img className="img-contain" src={isAuthenticated && user.emailVerified ? '/icon/user-active.svg' : '/icon/user.svg'} alt="user" />
            </button>
          </li>
          <li>
            <button className="header__icons-item" type="button" onClick={() => setActiveWindow('basket')}>
              {busketNumber.length > 0 && <div className="header__icons-item-busket">{busketNumber.length}</div>}
              <img className="img-contain" src="/icon/shopping-cart.svg" alt="favorite" />
            </button>
          </li>
        </ul>

        <div onClick={() => handleBurger()} className={`header__burger ${activeWindow ? 'header__burger-active' : ''} `}>
          <img src={activeWindow ? './icon/burger-open.svg' : './icon/burger-icon.svg'} alt="burger" />
        </div>
      </div>
    </header>
  );
}
