import React, { useContext, useEffect, useState } from 'react';
import Item from '../Item/Item';
import { Context } from '../../Context';
import './Views.css';

export default function Views() {
  const { data } = useContext(Context);
  const [views, setViews] = useState(false);

  useEffect(() => {
    const storedViews = JSON.parse(localStorage.getItem('military-views')) || [];
    
    if (storedViews.length > 0 && data) {
      const filteredProducts = data.filter((product) => storedViews.includes(product.id));
      setViews(filteredProducts);
    }
  }, [data]);

  return views ? (
    <div>
      <div className="views-recommended">Товари які ви вже передивлялись</div>
      <ul className="category__item-list views-recommended-list">
        {views.map((item, index) => {
          return <Item item={item} key={index} />;
        })}
      </ul>
    </div>
  ) : null;
}
