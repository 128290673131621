import { useContext } from 'react';
import { Context } from '../Context';
import { useNavigate } from 'react-router-dom';

export const useFilterChange = () => {
  const { setSelectedItems } = useContext(Context);

  const navigate = useNavigate();
  
  const filterChange = (overwrite, type, value, page) => {

    setSelectedItems((prevState) => {
      const updatedItems = { ...prevState };

      if (overwrite) {
        // Перезаписать значение
        updatedItems[type] = value;
      } else {
        // Добавить или удалить значение
        if (type === 'number') {
          updatedItems[type] = value;
        } else {
          updatedItems[type] = Array.isArray(updatedItems[type]) ? [...updatedItems[type]] : [];
          const index = updatedItems[type].indexOf(value);
          if (index === -1) {
            updatedItems[type].push(value);
          } else {
            updatedItems[type].splice(index, 1);
          }
        }
      }

      // Удаление параметра 'page' если передано page = true
      if (page) {
        delete updatedItems.page;
      }

      const newQueryString = Object.entries(updatedItems)
        .filter(([key, value]) => value && (Array.isArray(value) ? value.length > 0 : true))
        .map(([key, value]) => `${key}=${Array.isArray(value) ? value.join(',') : value}`)
        .join('&');

      navigate(`?${newQueryString}`, { replace: true });

      return updatedItems;
    });
  };

  return filterChange;
};
