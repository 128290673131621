import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebase';
import { setDoc, doc } from 'firebase/firestore';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { Context } from '../../Context';
import { Loader } from '../Loader/Loader';
import './UserModal.css';

const UserModal = ({ onClose }) => {
  const { showModal } = useContext(Context);
  const [message, setMessage] = useState('');
  const [formData, setFormData] = useState({
    firstName: '',
    secondName: '',
    email: '',
    phone: '',
    password: '',
    secondPassword: '',
  });
  const [activeBtn, setActiveBtn] = useState(1);
  const [passwordFlag, setPasswordFlag] = useState(false);
  const [regFlag, setRegFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (showModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [showModal]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      document.body.classList.remove('modal-open');
      onClose();
    }
    document.body.style.overflow = 'auto';
  };

  const singIn = () => {
    setIsLoading(true);
    const { email, password } = formData;
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        if (user.emailVerified) {
          document.body.classList.remove('modal-open');
          onClose();
          if (location.pathname === '/product' || location.pathname === '/about-us' || location.pathname === '/payment') {
            document.body.classList.remove('modal-open');
            onClose();
          } else {
            navigate('/user');
          }
        } else {
          auth.signOut();
          setMessage('Ваш email не підтверджений. Підтвердіть пошту перед входом');
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        setMessage(errorCode);
      });
    setIsLoading(false);
  };

  const registration = () => {
    setIsLoading(true);
    const { email, password, firstName, secondName, phone } = formData;
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        sendEmailVerification(user)
          .then(() => {
            setMessage('Реєстрація успішна. Будь ласка, перевірте свою електронну пошту для підтвердження.');
            auth.signOut();
          })
          .catch((error) => {
            setMessage('Помилка відправки електронного листа для підтвердження.');
          });
        setDoc(doc(db, 'users', user.uid), {
          email: email,
          phone: phone,
          firstName: firstName,
          secondName: secondName,
          orders: [],
          favorite: [],
        }).then(() => {
          // console.log('Додаткові дані успішно збережені.');
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        setMessage(errorCode);
      });
    setIsLoading(false);
  };

  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    if (activeBtn === 2) {
      if (formData.password !== formData.secondPassword) {
        setPasswordFlag(true);
        setFormData((prevData) => ({
          ...prevData,
          secondPassword: '',
        }));
        setMessage('');
      } else {
        registration();
      }
    } else {
      singIn();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (formData.password === formData.secondPassword) {
      setPasswordFlag(false);
    }
  }, [formData.secondPassword, formData.password]);

  useEffect(() => {
    if (message === 'Реєстрація успішна. Будь ласка, перевірте свою електронну пошту для підтвердження.') {
      setRegFlag(true);
    }
  }, [message]);

  const handleBackClick = () => {
    document.body.classList.remove('modal-open');
    onClose();
    navigate('/');
  };

  const inputFieldsReg = [
    { type: 'text', id: 'firstName', name: 'firstName', value: formData.firstName, placeholder: 'Імʼя*', check: false },
    { type: 'text', id: 'secondName', name: 'secondName', value: formData.secondName, placeholder: 'Прізвище*', check: false },
    { type: 'tel', id: 'phone', name: 'phone', value: formData.phone, placeholder: 'Телефон*', check: false },
    { type: 'email', id: 'email', name: 'email', value: formData.email, placeholder: 'Email*', check: false },
    { type: 'password', id: 'password', name: 'password', value: formData.password, placeholder: 'Пароль*', check: false },
    { type: 'password', id: 'secondPassword', name: 'secondPassword', value: formData.secondPassword, placeholder: 'Введіть пароль повторно*', check: true },
  ];

  const inputFieldsLog = [
    { type: 'email', id: 'email', name: 'email', value: formData.email, placeholder: 'Email*' },
    { type: 'password', id: 'password', name: 'password', value: formData.password, placeholder: 'Пароль*' },
  ];

  const handleBtnChangeClick = (ind) => {
    setActiveBtn(ind);
    setFormData({
      firstName: '',
      secondName: '',
      email: '',
      phone: '',
      password: '',
      secondPassword: '',
    });
    setMessage('');
    setRegFlag(false);
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="modal__overlay" onClick={handleBackgroundClick}>
        <div className="modal__content">
          <span
            className="modal__close"
            onClick={() => {
              document.body.classList.remove('modal-open');
              onClose();
            }}
          >
            &times;
          </span>
          {!regFlag && <p className="modal__text">Вхід</p>}
          {!regFlag && <p className="modal__title">Увійдіть в уже існуючий особистий кабінет або зареєструйтесь як новий покупець.</p>}
          {message && !regFlag && (
            <p className="modal_error-message">
              {message === 'auth/email-already-in-use'
                ? 'Цей email вже використовується, увійдіть в систему або вкажіть іншу пошту'
                : message === 'auth/weak-password'
                ? 'Пароль має містити мінімум 6 символів'
                : message === 'auth/invalid-email'
                ? 'Неправильно введений email, перевірте та спробуйте ще раз'
                : message === 'auth/invalid-credential'
                ? 'Невірний логін чи пароль'
                : message}
            </p>
          )}
          {!regFlag ? (
            <div className="modal__form-wrap">
              <form onSubmit={handleSubmit} className="modal__form">
                <ul className="modal__form-list">
                  {activeBtn === 2 &&
                    inputFieldsReg.map((item) => (
                      <li key={item.id}>
                        <p className={`modal__form-list-item--placeholder ${item.name === 'secondPassword' && passwordFlag && 'modal__form-list-item--placeholder--error'}`}>
                          {item.name === 'secondPassword' && passwordFlag ? 'Паролі не співпадають*' : item.placeholder}
                        </p>
                        <input type={item.type} id={item.id} name={item.name} value={item.value} onChange={handleInputChange} className="modal__form-list-item" required />
                      </li>
                    ))}
                  {activeBtn === 1 &&
                    inputFieldsLog.map((item) => (
                      <li key={item.id}>
                        <p className="modal__form-list-item--placeholder">{item.placeholder}</p>
                        <input type={item.type} id={item.id} name={item.name} value={item.value} onChange={handleInputChange} className="modal__form-list-item" required />
                      </li>
                    ))}
                </ul>
                <ul className="modal__button-list">
                  <li>
                    <button className="modal__button-list-item" type="submit">
                      {activeBtn === 1 ? 'Увійти до особистого кабінету' : 'Зареєструватись'}
                    </button>
                  </li>
                  <li>
                    <button
                      className="modal__button-list-item modal__button-list-item--transparent"
                      onClick={() => {
                        if (activeBtn === 1) {
                          handleBtnChangeClick(2);
                        } else {
                          handleBtnChangeClick(1);
                        }
                      }}
                      type="button"
                    >
                      {activeBtn === 2 ? 'Увійти до особистого кабінету' : 'Зареєструватись'}
                    </button>
                  </li>
                </ul>
              </form>
            </div>
          ) : (
            <div className="modal__before-wrap">
              <p className="modal__before-title">Вітаємо!</p>
              <p className="modal__before-text">Реєстрація пройшла успішно</p>
              <p className="modal__before-text modal__before-text--green">Будь ласка, перевірте електронну пошту та підтвердіть свій обліковий запис.</p>
              <button className="modal__before-btn" onClick={handleBackClick}>
                Перейти на головну
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserModal;
