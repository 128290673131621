import React, { useEffect, useState } from 'react';
import { db } from '../../../../firebase';
import { getDocs, collection, doc, setDoc } from 'firebase/firestore';
import './Sales.css';

export default function Sales({ alert, open }) {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showOptGreaterThanZero, setShowOptGreaterThanZero] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'users'));
        if (!querySnapshot.empty) {
          const newData = [];
          querySnapshot.forEach((doc) => {
            newData.push({ id: doc.id, ...doc.data() });
          });
          setUsers(newData);
        }
      } catch (error) {
        console.error('Error getting users: ', error);
      }
    };

    fetchData();
  }, []);

  // const handleInputChange = (id, value) => {
  //   console.log(value);
    
  //   if (value <= 100) {
  //     setUsers((prevUsers) => prevUsers.map((user) => (user.id === id ? { ...user, opt: value } : user)));
  //   }
  // };
  const handleInputChange = (id, value) => {

    setUsers((prevUsers) =>
      prevUsers.map((user) => {
        if (user.id === id && value <= 100) {
          // Если value пустая строка или 0, удаляем opt
          if (value === '' || value === 0 || value === '0') {
            const { opt, ...rest } = user;
            return rest;
          }
          // В противном случае обновляем opt
          return { ...user, opt: value };
        }
        return user;
      }),
    );
  };

  const handleSave = async (id) => {
    const user = users.find((user) => user.id === id);
    if (user) {
      try {
        const userDocRef = doc(db, 'users', id);
        await setDoc(userDocRef, user);
        alert('Оновлено');
        open(true);
      } catch (error) {
        console.error(`Error updating user ${id}: `, error);
      }
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCheckboxChange = () => {
    setShowOptGreaterThanZero(!showOptGreaterThanZero);
  };

  const filteredUsers = users.filter((user) => {
    const matchesSearchTerm = user.email.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesOptFilter = showOptGreaterThanZero ? user.opt > 0 : true;
    return matchesSearchTerm && matchesOptFilter;
  });

  return (
    <div className="setting__item-wrapper">
      <div className="setting__seles-inputs">
        <input className="setting__seles-inputs-search" type="text" placeholder="Пошук по email" value={searchTerm} onChange={handleSearchChange} />
        <label className="setting__seles-inputs-checkbox">
          <input type="checkbox" checked={showOptGreaterThanZero} onChange={handleCheckboxChange} />
          Показати тільки зі знижкою
        </label>
      </div>
      <div className="setting__item-wrapper">
        {filteredUsers.length > 0 &&
          filteredUsers.map((item) => (
            <div className="setting__seles-wrapper" key={item.id}>
              <div className="setting__seles-item">{item.email}</div>
              <div className={`setting__seles-number ${item.opt && item.opt > 0 ? 'setting__seles-number-active' : ''}`}>{item.opt ? item.opt : '0'}%</div>
              <input
                className="setting__seles-input"
                type="number"
                placeholder="знижка"
                value={item.opt || ''}
                max="100"
                onChange={(e) => handleInputChange(item.id, e.target.value)}
              />
              <button className="setting__comment-item-edit" onClick={() => handleSave(item.id)}>
                зберегти
              </button>
            </div>
          ))}
      </div>
    </div>
  );
}
