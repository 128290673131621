import React, { useContext } from 'react';
import { Context } from '../../../Context';
import { Link, useNavigate } from 'react-router-dom';
import { text, menuLinks } from './../../Header/header-text';
import './Burger.css';

export default function Burger() {
  const { busketNumber, setActiveWindow, isAuthenticated, setShowModal, userData } = useContext(Context);

  const navigate = useNavigate();
  const handleClick = () => {
    if (isAuthenticated) {
      navigate('/user');
    } else {
      setShowModal(true);
      document.body.style.overflow = 'hidden';
    }
  };

  return (
    <div className="burger">
      <ul className="burger__icons">
        <li>
          <button className="header__icons-item" type="button" onClick={() => setActiveWindow('basket')}>
            {busketNumber.length > 0 && <div className="header__icons-item-busket">{busketNumber.length}</div>}
            <img className="img-contain" src="/icon/shopping-cart.svg" alt="favorite" />
          </button>
        </li>
        <li className="header__icons-wrapper" onClick={() => setActiveWindow(false)}>
          {userData && isAuthenticated && (
            <button className="header__icons-info" type="button" onClick={handleClick}>
              {userData.firstName}
            </button>
          )}

          <button className={isAuthenticated ? 'header__icons-item-active' : 'header__icons-item'} type="button" onClick={handleClick}>
            <img className="img-contain" src={isAuthenticated ? '/icon/user-active.svg' : '/icon/user.svg'} alt="user" />
          </button>
        </li>
      </ul>

      <ul className="burger__menu">
        <li>каталог</li>
        {menuLinks.map((item, index) => (
          <li key={index}>
            <Link onClick={() => setActiveWindow(false)} to={item.to}>
              {item.text}
            </Link>
          </li>
        ))}
      </ul>

      <ul className="burger__links">
        {text.map((item, index) => (
          <li key={index}>
            <Link to={item.to} onClick={() => setActiveWindow(false)}>
              {item.text}
            </Link>
          </li>
        ))}
      </ul>

      <ul className="burger__social">
        <li>
          <a href="https://www.facebook.com/profile.php?id=61557752902720" target="blank">
            <img className="img-contain" src="/icon/facebook.svg" alt="facebook" />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/zahysnik.zp?igsh=MTI0NW03Znhyc21sbA%3D%3D&utm_source=qr" target="blank">
            <img className="img-contain" src="/icon/instagram.svg" alt="instagram" />
          </a>
        </li>
      </ul>
    </div>
  );
}
