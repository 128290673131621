import React from 'react';
import './NotFound.css';

export default function NotFound() {

  return (
    <div className="notFound">
      <p className="notFound__title">404</p>
      <p className="notFound__text">Ой...</p>
      <p className="notFound__text">Схоже, сторінка була втрачена</p>
    </div>
  );
}
