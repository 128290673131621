import React, { useEffect, useContext } from 'react';
import { Context } from '../../Context';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';

export default function RequireAdminAuth({ children }) {
  const { admin, setShowModal } = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAdminAuth = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          // Якщо користувач не авторизований, перенаправляємо на сторінку входу
          navigate('/');
          setShowModal(true);
        }
      } catch (error) {
        console.error('Помилка перевірки автентифікації:', error);
      }
    };
    checkAdminAuth();
    // eslint-disable-next-line
  }, [admin]);

  return <>{children}</>;
}
