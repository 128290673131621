import React, { useContext } from 'react';
import { Context } from '../../Context';
import Basket from './Basket/Basket';
import ClientFilter from './ClientFilter/ClientFilter';
import Burger from './Burger/Burger';
import './Window.css';

export default function Window() {
  const { activeWindow, setActiveWindow, cookiesActive } = useContext(Context);

  return (
    <>
      <div onClick={() => setActiveWindow(false)} className={`window__black ${activeWindow || cookiesActive ? 'window__black-active' : ''}`}></div>
      <div className={`window ${activeWindow === 'basket' || activeWindow === 'filter' || activeWindow === 'burger' ? 'window-active' : ''}`}>
        <button onClick={() => setActiveWindow(false)} className="window-close">
          Закрити
        </button>
        {activeWindow === 'basket' && <Basket />}
        {activeWindow === 'filter' && <ClientFilter />}
        {activeWindow === 'burger' && <Burger />}
      </div>
    </>
  );
}
