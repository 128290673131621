import React from 'react';

export default function DeliveryPayment() {
  return (
    <div className="privacy">
      <h1>ДОСТАВКА ТА ОПЛАТА</h1>
      <h2>Доставка товару.</h2>
      <p>Доставка товару здійснюється по всій території України будь-яким поштовим оператором. Вартість доставки визначається згідно тарифів перевізника. </p>
      <p>
        Обробка замовлення відбувається у робочі дні з понеділка по п’ятницю з 9:00 по 18:00. Після оформлення замовлення з Вами зв’яжеться консультант для уточнення деталей
        замовлення, підходящого способу доставки та оплати товару.
      </p>
      <p>Шановні клієнти, просимо звернути Вашу увагу, що у зв’язку з відключеннями світла по всій території України, строки обробки та доставки товару можуть бути зміненими!</p>
      <p>Доставка здійснюється як по повній оплаті товару, так і по післяплаті.</p>
      <h2>Оплата.</h2>
      <p>Ви можете здійснити оплату товару такими способами:</p>
      <ul>
        <li>
          1. Оплата товару одержувачем під час отримання посилки, після її повного огляду у відділені пошти. В такому разі Ви сплачуєте додаткову комісію в розмірі 2% за оплату
          накладеним платежем.
        </li>
        <li>2. Оплата товару за реквізитами на розрахунковий рахунок ФОП.</li>
      </ul>
      <h2>Обмін та повернення товару.</h2>
      <p>
        Повернення/обмін товару можливе протягом 14 днів з моменту покупки за наявності документу (накладна/чек) на товар. Це право гарантує вам Закон України «Про захист прав
        споживачів», крім товарів, що не підлягають обміну і поверненню згідно Постанови КМУ від 19.03.1994р №172.
      </p>
      <p>Придбаний товар обов'язково треба оглянути на пошті в присутності співробітника. При виявленні пошкодження/браку товару зв'язатися з менеджером.</p>
      <p>Повернення/обмін товару проводиться шляхом повернення придбаного товару в належній якості з подальшим відшкодуванням коштів покупцю.</p>
      <p>
        Вартість доставки товару для повернення/обміну сплачується покупцем. Якщо причиною повернення товару є виробничий брак, тоді всі витрати з пересилання товару бере наша
        компанія. В такому випадку, при відправленні товару у відділенні поштового оператора вкажіть, що оплачує посилку одержувач.
      </p>
      <h2>Гарантія повернення.</h2>
      <p>Гарантійні зобов'язання виконуються відповідно до Закону України "Про захист прав споживачів" (https://zakon.rada.gov.ua/laws/show/1023-12#Text).</p>
      <p>
        Товари належної якості, замовлені на сайті і не підійшли формою, габаритами, фасоном, кольором, розміром або з інших причин не може бути ним використаний за призначенням,
        підлягають поверненню та обміну протягом 14 днів, не рахуючи дня покупки, за умови:
      </p>
      <ul>
        <li>• Якщо він не використовувався.</li>
        <li>• Збережено товарний вигляд та споживчі властивості.</li>
        <li>• Збережено розрахунковий документ, виданий споживачеві разом з проданим товаром.</li>
      </ul>
    </div>
  );
}
