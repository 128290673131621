import React, { useContext } from 'react';
import { category } from './ClientFilter-Info';
import { colors } from '../../../pages/Admin/Setting/Setting-info';
import { Context } from '../../../Context';
import { useLocation } from 'react-router-dom';
import { useFilterChange } from '../../../functions/FilterChange';
import { pathFilters } from '../../../pages/Category/CategoryInfo';
import './ClientFilter.css';

export default function ClientFilter() {
  const location = useLocation();

  const { selectedItems, setActiveWindow } = useContext(Context);

  // Логика для фильтрации продуктов и установки списка
  let { mainFilter } = pathFilters[location.pathname];

  // функция для саписи фильтров
  const handleFilterChange = useFilterChange();

  return (
    <div className="client-filter">
      {location && location.pathname !== '/new' && location.pathname !== '/sale' && (
        <div div className="client-filter__wrapper">
          <p className="client-filter__text">Категорія</p>
          <ul className="client-filter__list">
            {mainFilter &&
              mainFilter.map((item, index) => {
                return (
                  <li key={index} className="text-no-touch client-filter__list-item" onClick={() => handleFilterChange(true, 'category', item.value, true)}>
                    <div
                      className={`client-filter__list-item-ok ${selectedItems.category && selectedItems.category.includes(item.value) ? 'client-filter__list-item--active' : ''}`}
                    ></div>
                    {item.label}
                  </li>
                );
              })}
          </ul>
        </div>
      )}

      {location && category[selectedItems.category[0]] && category[selectedItems.category[0]].length > 0 && (
        <div div className="client-filter__wrapper">
          <div className="client-filter__text">Підкатегорія</div>
          <ul className="client-filter__list">
            {location &&
              category[selectedItems.category[0]].map((item, index) => {
                return (
                  <li key={index} className="text-no-touch client-filter__list-item" onClick={() => handleFilterChange(false, 'subCategories', item.value)}>
                    <div
                      className={`client-filter__list-item-ok ${
                        selectedItems.subCategories && selectedItems.subCategories.includes(item.value) ? 'client-filter__list-item--active' : ''
                      }`}
                    ></div>
                    {item.label}
                  </li>
                );
              })}
          </ul>
        </div>
      )}

      <div className="client-filter__text">Колір</div>
      <ul className="client-filter__list">
        {location &&
          colors.map((item, index) => {
            return (
              <li key={index} className="text-no-touch client-filter__list-item" onClick={() => handleFilterChange(false, 'color', item.value, true)}>
                <div className={`client-filter__list-item-ok ${selectedItems.color && selectedItems.color.includes(item.value) ? 'client-filter__list-item-color-active' : ''}`}>
                  <div className={`client-filter__list-item-ok-color ${item.value}`}></div>
                </div>
                {item.label}
              </li>
            );
          })}
      </ul>

      <div className="client-filter__text">Ціна</div>

      <div className="client-filter__slider">
        <div className="client-filter__slider-item">
          Від
          <div className="client-filter__slider-item-number">0</div>
        </div>

        <div className="client-filter__slider-item">
          До
          <input
            value={selectedItems.number}
            onChange={(event) => handleFilterChange(true, 'number', event.target.value, true)}
            className="client-filter__slider-item-input"
            placeholder="100000"
            type="number"
          />
        </div>
      </div>

      <button onClick={() => setActiveWindow(false)} className="client-filter__button">
        Застосувати
      </button>
    </div>
  );
}
