import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../Context';
import { filter } from '../../functions/Filter';
import { Link } from 'react-router-dom';
import Item from '../../components/Item/Item';
import './List3item.css';
import { useMediaQuery } from 'react-responsive';

export default function List3item({ title, param, userData }) {
  const { data } = useContext(Context);

  const isIpad = useMediaQuery({ maxWidth: 1024 });
  const isIphone = useMediaQuery({ maxWidth: 576 });

  // записываем товары после фильтра
  const [list, setList] = useState(false);

  useEffect(() => {
    if (data) {
      const filters = param === 'new' ? { newSale: 'new' } : { bestsellers: true };
      const filteredProducts = filter(data, filters);
      setList(filteredProducts);
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <div className="list3item">
      <Link to={title === 'наші бестселери' ? '/bestsellers' : '/new'} className="list3item__title">
        {title}
      </Link>
      <ul className="category__item-list list3item__list-mobile">
        {list &&
          list.slice(0, isIphone ? 4 : isIpad ? 3 : 4).map((item, index) => {
            return <Item item={item} key={index} userData={userData} />; // Отображение товаров
            // return (
            //   <li key={index} onClick={() => navigate(`/${item.id}`)} className="list3item__item">
            //     <div className="list3item__item-img">
            //       <img src={item.img} alt={item.title} />
            //     </div>
            //     <p className="list3item__item-title">{item.title}</p>
            //     <p className="list3item__item-category">{item.category}</p>
            //     <p className="list3item__item-price">
            //       {item.priceSale && !userData?.opt && item.priceSale !== '' ? (
            //         <>
            //           ₴ {Math.floor(item.price * (1 - item.priceSale / 100))} <span className="list3item__item-price-sale">₴ {item.price}</span>
            //         </>
            //       ) : userData?.opt > 0 ? (
            //         <>
            //           ₴ {Math.floor(item.price * (1 - userData?.opt / 100))} <span className="list3item__item-price-sale">₴ {item.price}</span>
            //         </>
            //       ) : (
            //         <>₴ {item.price}</>
            //       )}
            //     </p>
            //   </li>
            // );
          })}
      </ul>
    </div>
  );
}
