import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../Context';
import './UserProductItem.css';

export default function UserProductItem({ order, devList, onRemove }) {
  const { data, userData } = useContext(Context);
  const { items, date } = order;
  const [shouldRender, setShouldRender] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 576 });

  const navigate = useNavigate();

  useEffect(() => {
    if (devList) {
      const hasMatchingItems = items.some((item) => data.some((dataItem) => dataItem.id === item.id));
      setShouldRender(hasMatchingItems);
    } else {
      setShouldRender(true);
    }
  }, [data, items, devList]);

  // Якщо немає відповідних елементів, не рендеримо
  if (!shouldRender) {
    return null;
  }

  // Функція для форматування дати з 'yyyymmdd' у 'дд.мм.рррр'
  const formatDateString = (dateString) => {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    return `${day}.${month}.${year}`;
  };

  const handleToProduct = (productID) => {
    navigate(`/${productID}`);
  };

  // перевірка ціни

  const updatedItemList = (price, priceSale) => {
    return userData?.opt ? Math.floor(price * (1 - userData.opt / 100)) : priceSale ? Math.floor(price * (1 - priceSale / 100)) : price;
  };

  return (
    <li className={`user__order-item ${devList && isMobile && 'user__order-item--mobile'} `}>
      {(devList ? items : [order]).map((item, index) => {
        const { title, img, price, priceSale, producer, quantity, color, size, id } = item;
        return (
          <div className="user__order-item-wrap" key={index}>
            <div className="user__order-item-img" onClick={() => handleToProduct(id)}>
              <img src={img[0]} alt={title} />
            </div>
            <div className="user__order-item-info">
              <div className="user__order-item-info-left">
                <p className="user__order-item-title" onClick={() => handleToProduct(id)}>
                  {title}
                </p>
                <p className="user__order-item-producer">{producer}</p>
                {!isMobile && (
                  <div className="user__order-item-characteristic">
                    {quantity && <p>Кількість: {quantity}</p>}
                    <p>Колір: {color}</p>
                    <p>Розмір: {size}</p>
                  </div>
                )}
              </div>
              <div className={`user__order-item-info-price--wrap ${!devList ? 'user__order-item-info-price--wrap-button' : 'user__order-item-info-price--wrap--absolute'}`}>
                {devList && (
                  <div className={`user__order-item-info-price ${devList ? 'user__order-item-info-price--mtAuto' : 'user__order-item-info-price--mtCouple'}`}>{price} грн</div>
                )}
                {!devList && (
                  <div className={`user__order-item-info-price ${devList ? 'user__order-item-info-price--mtAuto' : 'user__order-item-info-price--mtCouple'}`}>
                    {updatedItemList(price, priceSale)} грн
                  </div>
                )}
                {!devList && (
                  <div className="user__order-item-info-button-wrap">
                    <button className="user__order-item-info-button user__order-item-info-button--dark" onClick={() => onRemove(id)}>
                      Видалити
                    </button>
                    <button className="user__order-item-info-button" onClick={() => handleToProduct(id)}>
                      До товару
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
      {devList ? <div className="user__order-item-date">{formatDateString(date)}</div> : false}
    </li>
  );
}
