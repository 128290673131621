export const handleStorageUpdate = (item, storageKey, isAdd) => {
  const storedItems = localStorage.getItem(storageKey);

  if (storedItems) {
    const parsedItems = JSON.parse(storedItems);

    const updatedItems = isAdd ? [...parsedItems, item] : parsedItems.filter((product) => !(product.id === item.id && product.size === item.size));

    localStorage.setItem(storageKey, JSON.stringify(updatedItems));
  } else {
    if (isAdd) {
      const updatedItems = [item];
      localStorage.setItem(storageKey, JSON.stringify(updatedItems));
    }
  }
};
