export const collectionArray = [
  { value: 'clothes', label: 'Одяг' },
  { value: 'footwear', label: 'Взуття' },
  { value: 'tactical-equipment', label: 'Тактичне спорядження' },
  { value: 'ballistic-protection', label: 'Балістичний захист' },
  { value: 'equipment', label: 'Спорядження та екіпірування' },
  { value: 'weapon-accessories', label: 'Збройові аксесуари' },
];

export const categoryArray = [
  { value: 'штани', label: 'Штани' },
  { value: 'шорти', label: 'Шорти' },
  { value: 'куртки вітровки парки кітеля', label: 'Куртки, вітровки, парки, кітеля' },
  { value: 'кофти', label: 'Кофти' },
  { value: 'футболки', label: 'Футболки' },
  { value: 'головні убори', label: 'Головні убори' },
  { value: 'кросівки', label: 'Кросівки' },
  { value: 'черевики', label: 'Черевики' },
  { value: 'берці', label: 'Берці' },
  { value: 'аксесуари для самозахисту', label: 'Аксесуари для самозахисту' },
  { value: 'кобури', label: 'Кобури' },
  { value: 'розвантажувальні системи', label: 'Розвантажувальні системи' },
  { value: 'догляд за зброєю', label: 'Догляд за зброєю' },
  { value: 'захист колін і ліктів', label: 'Захист колін і ліктів' },
  { value: 'чохли для шоломів', label: 'Чохли для шоломів' },
  { value: 'ремені збройові', label: 'Ремені збройові' },
  { value: 'тактичні пояси', label: 'Тактичні пояси' },
  { value: 'чохли для зброї', label: 'Чохли для зброї' },
  { value: 'збройові ремені', label: 'Збройові ремені' },
  { value: 'сумки', label: 'Сумки' },
  { value: 'спальники / каремати', label: 'Спальники / каремати' },
  { value: 'нашивки / кокарди / погони', label: 'Нашивки / кокарди / погони' },
  { value: 'прапори', label: 'Прапори' },
  { value: 'плитоноски', label: 'Плитоноски' },
  { value: 'розвантажувальні системи', label: 'Розвантажувальні системи' },
  { value: 'стегнові платформи', label: 'Стегнові платформи' },
  { value: 'пояси', label: 'Пояси' },
  { value: 'рюкзаки', label: 'Рюкзаки' },
  { value: 'поло футболки майки', label: 'Поло, футболки, майки' },
  { value: 'балістичний захист', label: 'Балістичний захист' },
  { value: 'кавер на шолом', label: 'Кавер на шолом' },
  { value: 'органайзери утилітарні', label: 'Органайзери утилітарні' },
  { value: 'сумки під планшет', label: 'Сумки під планшет' },
  { value: 'підсумки', label: 'Підсумки' },
  { value: 'медичні підсумки', label: 'Медичні підсумки' },
  { value: 'підсумки для магазинів', label: 'Підсумки для магазинів' },
  { value: 'сумки для туалетного приладдя', label: 'Сумки для туалетного приладдя' },
];

export const subcategoriesArray = [
  { value: 'літні', label: 'Літні' },
  { value: 'зимові', label: 'Зимові' },
  { value: 'демісезонні', label: 'Демісезонні' },
  { value: 'шотри', label: 'Шорти' },
  { value: 'тактичні', label: 'Тактичні' },
  { value: 'вітрівки', label: 'Вітрівки' },
  { value: 'фліс', label: 'Фліс' },
  { value: 'светри', label: 'Светри' },
  { value: 'убокс', label: 'Убокс' },
  { value: 'кепки', label: 'Кепки' },
  { value: 'панами', label: 'Панами' },
  { value: 'шапки', label: 'Шапки' },
  { value: 'сумка-баул', label: 'Сумка - баул' },
  { value: 'плечові-пояси-для-тактичних-поясів', label: 'Плечові пояси для тактичних поясів' },
  { value: 'підсумок-під-рацію', label: 'Підсумок під рацію' },
  { value: 'поло', label: 'Поло' },
  { value: 'джоґери', label: 'Джоґери' },
  { value: 'сумка-бананка', label: 'Сумка - бананка' },
  { value: 'підсумки для гранат', label: 'Підсумки для гранат' },
  { value: 'cумки-через-плече', label: 'Сумки через плече' },
  { value: 'штани-тактичні', label: 'Штани тактичні' },
  { value: 'підсумки-для-магазинів', label: 'Підсумки для магазинів' },
  { value: 'підсумки-для-турнікетів', label: 'Підсумки для турнікетів' },
  { value: 'сумки-для-скидання-магазинів', label: 'Сумки для скидання магазинів' },
  { value: 'кобура-на-пояс', label: 'Кобура на пояс' },
  { value: 'кобура-на-стегно', label: 'Кобура на стегно' },
];

export const colors = [
  { value: 'multicam', label: 'multicam' },
  { value: 'pixel', label: 'pixel' },
  { value: 'olive', label: 'olive' },
  { value: 'predator', label: 'predator' },
  { value: 'coyote', label: 'coyote' },
  { value: 'black', label: 'чорний' },
];

export const newSale = [
  { value: false, label: 'Скинути New або Sale' },
  { value: 'sale', label: 'Sale' },
  { value: 'new', label: 'New' },
];

export const inputFields = [
  { name: 'title', type: 'text', placeholder: 'Назва товару', req: true },
  { name: 'price', type: 'number', placeholder: 'Ціна', req: true },
  { name: 'article', type: 'text', placeholder: 'Артикль', req: true },
  { name: 'size', type: 'text', placeholder: 'Розмір' },
];

export const inputSale = [{ name: 'priceSale', type: 'number', placeholder: 'Знижка у %', req: true }];

export const selectFields = [
  { name: 'collection', placeholder: 'Обрати категорію *', options: collectionArray, req: true, multiple: false },
  { name: 'category', placeholder: 'Обрати підкатегорію ++', options: categoryArray, multiple: false },
  { name: 'subCategories', placeholder: 'Обрати підкатегорію 2 ++', options: subcategoriesArray, multiple: false },
  { name: 'color', placeholder: 'Обрати колір *', options: colors, req: true, multiple: false },
  { name: 'newSale', placeholder: 'Обрати New або Sale ++', options: newSale, multiple: false },
];

export const textAreaFields = [
  { name: 'description', placeholder: 'Опис' },
  { name: 'characteristic', placeholder: 'Характеристика' },
];

export const checkboxes = [
  { name: 'bestsellers', label: 'Bestsellers' },
  { name: 'hide', label: 'Сховати товар' },
];
