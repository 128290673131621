export const firstList = [
  'Куртки: зимові, весняні, демісезонні, літні; ',
  'Костюми горки (в різних кольорах); ',
  'Тактичні штани різних моделей і кольорів; ',
  'Шапки, панами, светри, фліси різних моделей і кольорів;',
  'Термобілизна, бойова сорочка (убакс), маскувальні костюми (зимові і літні).',
];
export const secondList = [
  'РПС, плитоноски, бронежилети прихованого носіння ',
  'Балістичні вироби з ВМПЕ (високомолекулярний поліетилен) і кевлара: для шиї, спини, плечей, напашники, балістика на ногу ',
  'Підсумки під АК, РПК, сумки скидання, підсумки для різних застосувань ',
  'Кобури: на стегно, на ремінь з кріпленням Molle, оперативні кобури ',
  'Рюкзаки, сумки, баули, сумки з однією лямкою',
];
export const communicationList = [
  {
    src: './icon/about-us-email.svg',
    text: [{ t1: 'hello@relume.io', href: 'mailto:liberty.est2020@gmail.com' }],
  },
  {
    src: './icon/about-us-phone.svg',
    text: [{ t1: '+38 (095) 873-33-73' }, { t2: '+38 (095) 316-30-15' }, { t3: '+38 (066) 289-67-81' }, { t4: '+38 (095) 316-30-15' }],
  },
  {
    src: './icon/about-us-location.svg',
    text: [
      { t1: ['Київ, проспект Оболонський 51 В'] },
      { t2: ['Запоріжжя, пр. Соборний 44', 'пр. Соборний 143', 'пр. Соборний 172'] },
      { t3: ['Запоріжжя, пр. Соборний 26 ', 'вул. Космічна 121-Б'] },
      { t4: ['смт. Покровське, вул. Горького, 45 (в будівлі автовокзалу)', 'вул. Горького, 62'] },
      { t5: ['м. Покровськ, вул. Европейська, 22'] },
    ],
  },
];

export const formFields = [
  { label: "Ім'я", name: 'firstName', type: 'text' },
  { label: 'Прізвище', name: 'secondName', type: 'text' },
  { label: 'Телефон', name: 'phone', type: 'number' },
  { label: 'Email', name: 'email', type: 'email' },
];
