import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Window from "../../components/Window/Window";
import "./Container.css";

export default function Container({ children }) {
    return (
      <div className="container">
        <Window />
        <Header />
        {children}
        <Footer />
      </div>
    );
}
