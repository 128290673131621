import React from 'react';
import './PrivacyPolicy.css';

export default function PrivacyPolicy() {
  return (
    <div className="privacy">
      <h1>Політика конфіденційності</h1>
      <p>
        Компанії zahysnyk.com <br /> Редакція діє з 05.08.2014
      </p>
      <p>Ми відповідально ставимося до обробки і зберігання персональної інформації споживачів послуг компанії "zahysnyk.com", а також відвідувачів сайту https://zahysnyk.com/</p>
      <p>
        Персональна інформація - будь-яка інформація про особу, яка дозволяє прямо або побічно ідентифікувати цю особу, включаючи його персональні дані, в розумінні законодавства
        про захист персональних даних.
      </p>
      <p>
        Ця Політика конфіденційності містить опис різновидів персональної інформації, які ми отримуємо від користувачів наших сайтів (в тому числі, мобільної версії сайту) і
        клієнтів компанії "zahysnyk.com". Крім того, в цьому документі ми детально описуємо цілі, способи і методи збору та обробки вашої персональної інформації.
      </p>
      <p>Яку інформацію ми збираємо і навіщо.</p>
      <ul>
        <li>1. Метод і місце обробки персональної інформації.</li>
        <li>2. Поширення інформації.</li>
        <li>3. Захист особистих даних.</li>
        <li>4. Доступ до інформації.</li>
        <li>5. Cookie-файли, веб-маяки та інші технології.</li>
        <li>6. Реклама, спрямована на споживача.</li>
        <li>7. Посилання на інші сайти.</li>
        <li>8. Інші онлайн-послуги "zahysnyk.com".</li>
        <li>9. Внесення змін до Політики конфіденційності.</li>
        <li>10. Правова інформація.</li>
        <li>11. Наші контакти.</li>
      </ul>
      <h2>1. ЯКУ ІНФОРМАЦІЮ МИ ЗБИРАЄМО І НАВІЩО</h2>
      <p>Інформація, яку ви нам надаєте</p>
      <p>
        "zahysnyk.com" збирає і обробляє наступну інформацію: ім'я, прізвище та по батькові користувача (клієнта), поштова адреса, адреса електронної пошти та телефон. Ми збираємо
        тільки ті дані, які ви надаєте нам добровільно. Наприклад, ми можемо збирати персональну інформацію (дані вашого загальнодоступного профілю соціальної мережі) для того, щоб
        отримати від вас відгук про наші послуги, або ж ми запитуємо інформацію про місцезнаходження для реєстрації на сайті, з метою надання послуг або участі в акціях. Ви маєте
        право не надавати нам цю інформацію, але в цьому випадку, наш сайт і сервіси можуть працювати неправильно або ж частина їх функціоналу виявиться недоступною.
      </p>
      <p>
        Крім того, коли ви відвідуєте наш веб-сайт, ми можемо отримувати певну інформацію про вас автоматично. Така інформація може включати: ваш IP-адресу, відомості про
        операційну систему вашого комп'ютера / мобільного пристрою і тип браузера, тип вашого пристрою, унікальний ідентифікатор пристрою (UDID) або ж ідентифікатор мобільного
        устаткування (MEID) вашого мобільного пристрою, адреса сайту, на якому була розміщена посилання про нас і інформацію про сторінки, які ви відвідали на нашому веб-сайті.
      </p>
      <p>
        Ми можемо використовувати зазначену інформацію для управління, технічного обслуговування і налаштування нашого веб-сайту і сервісів. У цій Політиці конфіденційності ми
        також надаємо додаткову інформацію про те, як ми використовуємо файли «cookie».
      </p>
      <p>Мобільні пристрої та інформація про місцезнаходження</p>
      <p>
        Мобільна версія нашого сайту, з вашого окремого згоди, може отримувати інформацію про точне місцезнаходження мобільного пристрою, використовуючи технології GPS, Wi-Fi,
        Bluetooth або з огляду на відстань до найближчої базової станції мобільного зв'язку.
      </p>
      <p>
        Компанія "zahysnyk.com" використовує цю інформацію для надання послуг, в залежності від вашого місцезнаходження, наприклад для визначення спеціальних пропозицій для жителів
        вашого регіону, які можуть бути вам цікаві, або ж для персоналізації вашого взаємодії з компанією "zahysnyk.com".
      </p>
      <p>
        Ви завжди можете відкликати згоду на визначення вашого місцезнаходження, яке ви надали, відключивши збір даних про місцезнаходження в налаштуваннях вашого пристрою або
        вашого браузера. Якщо ви хочете відключити послугу визначення місця розташування на вашому мобільному пристрої, радимо звернутися до провайдера послуг мобільного зв'язку
        або виробнику мобільного пристрою. Якщо ви хочете, щоб ми видалили інформацію про місцезнаходження, будь ласка, надішліть нам лист за адресою, вказаною в останньому розділі
        цієї Політики. Зверніть увагу, що після видалення зазначених даних, деякі сервіси можуть працювати неправильно.
      </p>
      <h2>2. МЕТОД І МІСЦЕ ОБРОБКИ ПЕРСОНАЛЬНОЇ ІНФОРМАЦІЇ</h2>
      <p>метод обробки</p>
      <p>
        Обробка персональних даних здійснюється з використанням комп'ютерів і (або) засобів автоматизації відповідно до процедур і методами, які відповідають цілям збору даних.
      </p>
      <p>
        У деяких випадках, що збирається персональна інформація може бути доступна певним категоріям відповідальних осіб компанії "zahysnyk.com" (Оператор даних), пов'язаних із
        забезпеченням функціонування сайту (таким як адміністрація, відділ продажів, відділ маркетингу, колл-центр, юридичний відділ, системні адміністратори), або стороннім
        організаціям (таким як незалежні постачальники технічних послуг, поштові організації, постачальники послуг хостингу, компанії-постачальники інформаційних технологій, служби
        зв'язку), яким ми доручаємо, при необхідності, виконувати функції обробника даних. Ви можете додатково запросити у нас перелік зазначених осіб.
      </p>
      <p>Місце обробки даних</p>
      <p>
        Дані обробляються в операційних офісах кому компанії "zahysnyk.com" і в інших місцях, де знаходяться боку, займаються їх обробкою. За додатковою інформацією слід звертатися
        до нас (оператору даних і володільцю бази даних).
      </p>
      <h2>3. ВИКОРИСТАННЯ ПЕРСОНАЛЬНОЇ ІНФОРМАЦІЇ</h2>
      <p>З метою надання послуг, ваша персональна інформація може бути використана і оброблена всередині компанії "zahysnyk.com".</p>
      <p>Під компанією "zahysnyk.com" розуміється: Фізична особа-підприємець Падофет Анатолій Володимирович (РНУКПН / ІПН 2517900072).</p>
      <p>
        Будь-яке використання вашої персональної інформації всередині компанії "zahysnyk.com" здійснюється відповідно до цієї Політики конфіденційності. Час від часу, ми можемо
        направляти вам повідомлення, що містять маркетингову та рекламну інформацію про наші продукти та послуги, акційні пропозиції і т. Д. Якщо ви не надасте нам згоди на
        отримання зазначених повідомлень (в процесі реєстрації або замовлення послуг), ми не будемо вам їх направляти.
      </p>
      <p>
        "zahysnyk.com" має право залучати інші компанії для надання послуг та організації рекламних кампаній. Ці компанії також можуть отримати доступ до частини вашої персональної
        інформації, лише в тій частині, в якій це необхідно для надання послуг, при цьому, вони не мають права використовувати її для будь-яких інших цілей.
      </p>
      <p>
        zahysnyk.com не продає, не передає і не повідомляє персональну інформацію щодо наших клієнтів і користувачів нашого сайту третім особам, що не входять в групу компаній
        "zahysnyk.com". Але, за вашою згодою, ми можемо іноді направляти вам маркетингову інформацію від імені одного з наших партнерів про пропоновані товари і послуги, які можуть
        вас зацікавити. Ми також можемо запитати вас, чи бажаєте ви отримувати маркетингові матеріали наших бізнес-партнерів. Якщо ви погодитеся на отримання розсилки, ми не
        станемо повідомляти вашу персональну інформацію цим партнерам, а будемо направляти вам матеріали електронною поштою від імені цих партнерів.
      </p>
      <p>
        "zahysnyk.com" зберігає за собою право використовувати і повідомляти будь-яку інформацію про вас, у випадках, прямо встановлених законом або іншим нормативно-правовим
        актом, з метою захисту цілісності наших сервісів, захистити свої права або ж з метою сприяння правоохоронним органам в розслідуванні злочинів і забезпеченні громадської
        безпеки.
      </p>
      <h2>4. ЗАХИСТ ПЕРСОНАЛЬНОЇ ІНФОРМАЦІЇ</h2>
      <p>
        Компанія "zahysnyk.com" робить все комерційні обґрунтовані заходи для запобігання несанкціонованого доступу до персональної інформації і даних, отриманих в процесі
        користування сайтом або послугами "zahysnyk.com". Ми прагнемо захищати ваші персональні дані, проте ми закликаємо вас приймати всіх доступних заходів обережності для
        захисту ваших персональних даних під час їх передачі. Для цього частіше міняйте паролі, використовуйте комбінації букв і цифр, а також користуйтеся безпечним браузером.
      </p>
      <p>Забезпечення інформаційної безпеки дітей.</p>
      <p>
        Веб-сайт https://zahysnyk.com/ не орієнтований на залучення уваги дітей і неповнолітніх. Компанія "zahysnyk.com" навмисно не займається збором персональних даних дітей і
        осіб молодше 18 років, і не направляє їм ніяких запитів на надання персональної інформації.
      </p>
      <h2>5. ДОСТУП ДО ІНФОРМАЦІЇ</h2>
      <p>
        Ви повністю контролюєте всю персональну інформацію, яку надаєте нам. Ви маєте право в будь-який момент уточнити або змінити особисту інформацію, яку ми від вас отримали,
        або ж змінити налаштування отримання рекламної та маркетингової інформації від нас і наших партнерів. Ви маєте право в будь-який час попросити нас видалити всю зібрану про
        вас інформацію (право на забуття). Для цього надішліть нам повідомлення електронної пошти або лист, за адресою, вказаною в останньому розділі цієї Політики.
      </p>
      <h2>6. COOKIE-ФАЙЛИ, ВЕБ-МАЯКИ ТА ІНШІ ТЕХНОЛОГІЇ</h2>
      <p>
        Веб-сайт https://zahysnyk.com/ збирає інформацію про вашу поведінку на нашому сайті і відвідування сторінок сайту, з метою отримання статистики відвідувань і ефективності
        використання сайту, формування персонального підходу і адаптації наших сервісів до інтересів кожного користувача. Ми робимо це за допомогою різних технологій, одна з яких -
        cookie-файли. Це дані, які веб-сайт може відправити вашому браузеру, і вони будуть збережені на вашому комп'ютері для подальшої ідентифікації його веб-сайтом.
      </p>
      <p>
        Найчастіше cookie-файли використовуються для оцінки ефективності роботи веб-сайтів (наприклад, для вимірювання кількості відвідувачів і тривалості їх перебування на сайті),
        виявлення найбільш цікавих і відвідуваних тим і сторінок, а також для забезпечення зручності навігації та користування сайтом, без збору який -або персональної інформації.
        Також cookie-файли можуть бути використані для формування персонального підходу на основі вже наявного досвіду взаємодії користувача з сайтом і його перевагами. З плином
        часу ця інформація дозволяє поліпшити досвід.
      </p>
      <p>
        Cookie-файли зазвичай ділять на сесійні і постійні. Сесійні cookie-файли допомагають ефективно переміщатися по веб-сайту, таким чином, що один раз і запитана в рамках сесії
        інформація вже не буде запитуватися знову. Сесійні cookie-файли зберігаються в тимчасовій пам'яті і стираються з закриттям браузера. Постійні cookie-файли зберігають
        призначені для користувача настройки для поточних і наступних візитів (наприклад, спрощують авторизацію на сайті - дозволяють запам'ятати облікові дані користувача). Вони
        зберігаються на жорсткому диску комп'ютера або на накопичувачі мобільного пристрою і активізуються при запуску браузера. Ми використовуємо постійні cookie-файли для
        збереження інформації, наприклад, про мову і країні користувача.
      </p>
      <p>
        Якщо ви не бажаєте більше отримувати файли «cookie» або ж якщо ви хочете щоб вас інформували про отримання «cookie», налаштуйте ваш веб-браузер. Однак, якщо ви вимкніть всі
        файли «cookie», ви не зможете скористатися деякими сервісами і функціями сайту.
      </p>
      <h2>7. СПРЯМОВАНА (таргетовану) РЕКЛАМА</h2>
      <p>
        Ми використовуємо файли «cookie», Google Remarketing Tag, Facebook Pixel і інші рекламні технології для того, щоб направляти вам рекламу відповідно до ваших індивідуальних
        інтересами та уподобаннями. Цей розділ нашої Політики конфіденційності надає додаткову інформацію з цього питання.
      </p>
      <h2>8. ПОСИЛАННЯ НА ІНШІ САЙТИ</h2>
      <p>
        Якщо ви відвідуєте сторонні сайти, які не обслуговуються компанією "zahysnyk.com", переходячи по посиланнях, розміщених на нашому сайті, вам необхідно ознайомитися з їх
        умовами конфіденційності та іншими принципами роботи. Ми не несемо відповідальності за політику конфіденційності та практику збору даних інших компаній, і будь-яка
        інформація, яку ви надаєте таким компаніям, збирається ними відповідно до їх політикою конфіденційності.
      </p>
      <h2>9. ІНШІ САЙТИ І ОНЛАЙН-СЕРВІСИ КОМПАНІЇ KILLA.COM.UA</h2>
      <p>
        Всі сайти і онлайн-сервіси компанії "zahysnyk.com", підкоряються цій Політиці конфіденційності. В процесі надання послуг, компанія zahysnyk.com зобов'язується суворо
        виконувати правила забезпечення конфіденційності, викладені в цій Політиці.
      </p>
      <h2>10. ВНЕСЕННЯ ЗМІН ДО ПОЛІТИКУ КОНФІДЕНЦІЙНОСТІ</h2>
      <p>
        Ми маємо право в будь-який час вносити зміни в цю Політику, розмістивши нову редакцію політики конфіденційності на сайті https: //zahysnyk.com/. Ми настійно рекомендуємо
        часто перевіряти сторінку політики конфіденційності та дату внесення останніх змін, зазначену у верхній частині сторінки, щоб бути в курсі останніх змін.
      </p>
      <p>
        У разі незгоди з будь-якими змінами, внесеними в Політику, ви повинні припинити користуватися сайтом і послугами компанії "zahysnyk.com" і можете вимагати, щоб ми видалили
        вашу персональну інформацію.
      </p>
      <p>
        Справжня політика конфіденційності поширюється на всю персональну інформацію користувачів сайту і клієнтів компанії "zahysnyk.com", якою ми володіємо, незалежно від методу
        збору і способу її отримання.
      </p>
      <h2>11. ПРАВОВА ІНФОРМАЦІЯ</h2>
      <p>
        Справжня Політика конфіденційності відповідає вимогам законодавства України про захист персональних даних, а також вимогам законодавства більшості країн СНД і містить
        докладні відомості про даних, що збираються, мету, способи їх збору, обробки та використання.
      </p>
      <p>
        Примітка для європейських користувачів: справжня Політика конфіденційності підготовлена ​​на виконання обов'язків, передбачених Загальною регламентом щодо захисту даних
        (Регламент ЄС 2016/679 від 27 квітня 2016 року або GDPR - General Data Protection Regulation)
      </p>
    </div>
  );
}
