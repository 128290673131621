import React, { useContext, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Link } from 'react-router-dom';
import { Context } from '../../../Context';
import { colors } from '../../../pages/Admin/Setting/Setting-info';
import { getTranslation } from '../../../functions/Translation';
import { processProducts } from '../../../functions/ProcessProducts';
import './BasketItem.css';

export default function BasketItem({ item, removeItem, userData }) {
  const [quantityMain, setQuantityMain] = useState(1);

  const { setActiveWindow, data, setSum } = useContext(Context);

  useEffect(() => {
    setQuantityMain(item.quantity);
  }, [item.quantity]);

  // кнопка минус и плюс
  // const updateQuantity = (operation) => {
  //   const cartItems = localStorage.getItem('military');
  //   const parsedCartItems = JSON.parse(cartItems);
  //   if (operation === '-' && parsedCartItems && quantityMain > 1) {
  //     const indexToRemove = parsedCartItems.indexOf(id);
  //     parsedCartItems.splice(indexToRemove, 1);
  //     setQuantityMain(quantityMain - 1);
  //   } else if (operation === '+') {
  //     parsedCartItems.push(id);
  //     setQuantityMain(quantityMain + 1);
  //   }
  //   setBusketNumber(parsedCartItems);
  //   localStorage.setItem('military', JSON.stringify(parsedCartItems));
  // };
  // кнопка минус и плюс
  const updateQuantity = (operation) => {
    const cartItems = localStorage.getItem('military');
    const parsedCartItems = JSON.parse(cartItems) || [];

    if (operation === '-' && parsedCartItems.length > 0 && quantityMain > 1) {
      const indexToRemove = parsedCartItems.findIndex((product) => product.id === item.id && product.size === item.size);
      if (indexToRemove !== -1) {
        parsedCartItems.splice(indexToRemove, 1);
        setQuantityMain(quantityMain - 1);
      }
    } else if (operation === '+') {
      // Добавляем новый объект товара с таким же id и размером
      const newItem = { ...parsedCartItems.find((product) => product.id === item.id && product.size === item.size) };
      parsedCartItems.push(newItem);
      setQuantityMain(quantityMain + 1);
    }
    localStorage.setItem('military', JSON.stringify(parsedCartItems));
    const updatedCartItems = localStorage.getItem('military');
    const { total } = processProducts(data, updatedCartItems, userData);
    setSum(total);
  };

  return (
    <li className="basket__list-item">
      <Link onClick={() => setActiveWindow(false)} to={`${item.id}`} className="basket__list-item-img">
        <img src={item.img} alt={item.title} />
      </Link>
      <div className="basket__list-item-wrap">
        <div className="basket__list-item-title-delete-wrap">
          <Link onClick={() => setActiveWindow(false)} to={`${item.id}`} className="basket__list-item-title">
            {item.title}
          </Link>
          <div className="basket__list-item-delete">
            <img className="img-contain" src={'/icon/delete.svg'} alt="delete" onClick={removeItem} />
          </div>
        </div>

        <div className="basket__list-item-category">{item.category}</div>
        <div className="basket__list-item-count">
          <RemoveIcon
            onClick={() => updateQuantity('-')}
            sx={{
              cursor: 'pointer',
              color: '#black',
            }}
            className="basket__list-item-count-svg"
          />
          <span className="basket__list-item-count-number">{quantityMain}</span>
          <AddIcon
            onClick={() => updateQuantity('+')}
            sx={{
              cursor: 'pointer',
              color: '#black',
            }}
            className="basket__list-item-count-svg"
          />
        </div>

        <div className="basket__list-item-priceCount-wrap">
          <div className="basket__list-item-info">
            <p>Колір: {getTranslation(colors, item.color)}</p>
            {item.size && <p>Розмір: {item.size}</p>}
          </div>
          <div className="basket__list-item-price">
            {item.priceSale && !userData?.opt && item.priceSale !== '' ? (
              <>
                ₴ {Math.floor(item.price * (1 - item.priceSale / 100)) * quantityMain} <span className="basket__list-item-price-sale">₴ {quantityMain * item.price}</span>
              </>
            ) : userData?.opt ? (
              <>
                ₴ {Math.floor(item.price * (1 - userData.opt / 100)) * quantityMain} <span className="item__price-sale">₴ {quantityMain * item.price}</span>
              </>
            ) : (
              <>₴ {quantityMain * item.price}</>
            )}
          </div>
        </div>
      </div>
    </li>
  );
}
