export const pathFilters = {
  '/clothes': {
    collection: 'clothes',
    title: 'Одяг',
    mainFilter: [
      { value: 'штани', label: 'Штани' },
      // { value: 'куртки', label: 'Куртки' },
      { value: 'кофти', label: 'Кофти' },
      // { value: 'футболки', label: 'Футболки' },
      { value: 'головні убори', label: 'Головні убори' },
      { value: 'шорти', label: 'Шорти' },
      { value: 'куртки вітровки парки кітеля', label: 'Куртки, вітровки, парки, кітеля' },
      { value: 'поло футболки майки', label: 'Поло, футболки, майки' },
      { value: 'всі', label: 'Всі' },
    ],
  },
  '/footwear': {
    collection: 'footwear',
    title: 'Взуття',
    mainFilter: [
      { value: 'кросівки', label: 'Кросівки' },
      { value: 'черевики', label: 'Черевики' },
      { value: 'берці', label: 'Берці' },
      { value: 'всі', label: 'Всі' },
    ],
  },
  '/tactical-equipment': {
    collection: 'tactical-equipment',
    title: 'Тактичне спорядження',
    mainFilter: [
      { value: 'аксесуари для самозахисту', label: 'Аксесуари для самозахисту' },
      { value: 'розвантажувальні системи', label: 'Розвантажувальні системи' },
      { value: 'догляд за зброєю', label: 'Догляд за зброєю' },
      { value: 'підсумки', label: 'Підсумки' },
      { value: 'захист колін і ліктів', label: 'Захист колін і ліктів' },
      { value: 'чохли для шоломів', label: 'Чохли для шоломів' },
      { value: 'ремені збройові', label: 'Ремені збройові' },
      { value: 'тактичні пояси', label: 'Тактичні пояси' },
      { value: 'чохли для зброї', label: 'Чохли для зброї' },
      { value: 'збройові ремені', label: 'Збройові ремені' },
      { value: 'медичні-підсумки', label: 'Медичні підсумки' },
      { value: 'підсумки-для-магазинів', label: 'Підсумки для магазинів' },
      { value: 'сумки під планшет', label: 'Сумки під планшет' },
      { value: 'кавер на шолом', label: 'Кавер на шолом' },
      { value: 'кобури', label: 'Кобури' },
      { value: 'всі', label: 'Всі' },
    ],
  },
  '/ballistic-protection': {
    collection: 'ballistic-protection',
    title: 'Балістичний захист',
    mainFilter: [
      { value: 'плитоноски', label: 'Плитоноски' },
      { value: 'розвантажувальні системи', label: 'Розвантажувальні системи' },
      { value: 'стегнові платформи', label: 'Стегнові платформи' },
      { value: 'пояси', label: 'Пояси' },
      { value: 'всі', label: 'Всі' },
    ],
  },
  '/equipment': {
    collection: 'equipment',
    title: 'Спорядження та екіпірування',
    mainFilter: [
      { value: 'сумки', label: 'Сумки' },
      { value: 'рюкзаки', label: 'Рюкзаки' },
      { value: 'медичні-підсумки', label: 'Медичні підсумки' },
      { value: 'органайзери утилітарні', label: 'Органайзери утилітарні' },
      { value: 'спальники / каремати', label: 'Спальники / каремати' },
      { value: 'нашивки / кокарди / погони', label: 'Нашивки / кокарди / погони' },
      { value: 'прапори', label: 'Прапори' },
      { value: 'сумки-для-туалетного-приладдя', label: 'Сумки для туалетного приладдя' },
      { value: 'всі', label: 'Всі' },
    ],
  },
  '/weapon-accessories': {
    collection: 'weapon-accessories',
    title: 'Збройові аксесуари',
    mainFilter: [
      { value: 'кросівки', label: 'Кросівки' },
      { value: 'черевики', label: 'Черевики' },
      { value: 'берці', label: 'Берці' },
      { value: 'всі', label: 'Всі' },
    ],
  },
  '/sale': {
    newSale: 'sale',
    title: 'Знижки',
  },
  '/new': {
    newSale: 'new',
    title: 'Новинки',
  },
  '/bestsellers': {
    bestsellers: true,
    title: 'Бестселери',
  },
};
