export const category = {
  підсумки: [
    { value: 'сумки-для-скидання-магазинів', label: 'Сумки для скидання магазинів' },
    { value: 'підсумки-для-турнікетів', label: 'Підсумки для турнікетів' },
    { value: 'підсумки для гранат', label: 'Підсумки для гранат' },
    { value: 'підсумок-під-рацію', label: 'Підсумок під рацію' },
  ],
  штани: [
    { value: 'штани-тактичні', label: 'Штани тактичні' },
    { value: 'джоґери', label: 'Джоґери' },
  ],
  'головні убори': [{ value: 'панами', label: 'Панами' }],
  'сумки під планшет': [
    { value: 'панами', label: 'Панами' },
    { value: 'сумки під планшет', label: 'Сумки під планшет' },
  ],
  сумки: [
    { value: 'cумки-через-плече', label: 'Сумки через плече' },
    { value: 'сумка-бананка', label: 'Сумка - бананка' },
    { value: 'сумка-баул', label: 'Сумка - баул' },
  ],
  'поло футболки майки': [{ value: 'поло', label: 'Поло' }],
  'тактичні пояси': [{ value: 'плечові-пояси-для-тактичних-поясів', label: 'Плечові пояси для тактичних поясів' }],
  кобури: [{ value: 'кобура-на-пояс', label: 'Кобура на пояс' }],
};

