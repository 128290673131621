import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const storage = getStorage();

// додаємо 1 фото і повертаємо лінк
export const addPhoto = async (fileName, productImage) => {
  try {
    const storageRef = ref(storage, `image/${fileName}`);
    const snapshot = await uploadBytes(storageRef, productImage);
    const downloadURL = await getDownloadURL(snapshot.ref);
    console.log('File available at', downloadURL);
    return downloadURL; // Возвращаем URL-адрес
  } catch (error) {
    console.error('Error uploading or getting download URL:', error);
    throw error; // Пробрасываем ошибку дальше
  }
};

// export const getId = () => {
//   const now = new Date();
//   const year = now.getFullYear();
//   const month = now.getMonth() + 1;
//   const day = now.getDate();
//   const hours = now.getHours();
//   const minutes = now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes();
//   const seconds = now.getSeconds() < 10 ? `0${now.getSeconds()}` : now.getSeconds();
//   return `${year}${month}${day}${hours}${minutes}${seconds}`;
// };

const transliterate = (text) => {
  const charMap = {
    'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'H', 'Ґ': 'G', 'Д': 'D', 'Е': 'E', 'Є': 'Ye', 'Ж': 'Zh',
    'З': 'Z', 'И': 'Y', 'І': 'I', 'Ї': 'Yi', 'Й': 'Y', 'К': 'K', 'Л': 'L', 'М': 'M', 'Н': 'N', 'О': 'O',
    'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T', 'У': 'U', 'Ф': 'F', 'Х': 'Kh', 'Ц': 'Ts', 'Ч': 'Ch',
    'Ш': 'Sh', 'Щ': 'Shch', 'Ь': '', 'Ю': 'Yu', 'Я': 'Ya',
    'а': 'a', 'б': 'b', 'в': 'v', 'г': 'h', 'ґ': 'g', 'д': 'd', 'е': 'e', 'є': 'ye', 'ж': 'zh',
    'з': 'z', 'и': 'y', 'і': 'i', 'ї': 'yi', 'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o',
    'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u', 'ф': 'f', 'х': 'kh', 'ц': 'ts', 'ч': 'ch',
    'ш': 'sh', 'щ': 'shch', 'ь': '', 'ю': 'yu', 'я': 'ya'
  };

  return text
    .split('')
    .map(char => charMap[char] || char)
    .join('')
    .replace(/[^a-zA-Z0-9\s]/g, '')  // Удаление всех недопустимых символов, кроме пробелов
    .trim()                           // Удаление пробелов в начале и конце строки
    .replace(/\s+/g, '-')             // Замена пробелов на дефисы
    .replace(/-+/g, '-')              // Удаление лишних дефисов
    .toLowerCase();                   // Приведение к нижнему регистру
};

export const getId = (productName) => {
  const now = new Date();
  const month = now.getMonth() + 1;
  const day = now.getDate();
  const hours = now.getHours();
  const minutes = now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes();
  const seconds = now.getSeconds() < 10 ? `0${now.getSeconds()}` : now.getSeconds();

  // Транслитерация названия товара
  const transliteratedName = transliterate(productName);

  // Создание финального ID
  return `${transliteratedName}-${month}${day}${hours}${minutes}${seconds}`;
};

