import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../../../Context';
import BasketItem from '../BasketItem/BasketItem';
import { handleStorageUpdate } from '../../../functions/AddBasket';
import { processProducts } from '../../../functions/ProcessProducts';
import './Basket.css';

export default function Basket() {
  const { data, setSum, sum, setActiveWindow, userData } = useContext(Context);

  const local = localStorage.getItem('military');

  // записываем товары
  const [itemList, setItemList] = useState(false);

  // логика отображенния товаров
  useEffect(() => {
    if (data && local && local.length > 0) {
      const { updatedProducts, total } = processProducts(data, local, userData);
      setItemList(updatedProducts);
      setSum(total);
    } else {
      setItemList(false);
    }
    // eslint-disable-next-line
  }, [data]);

  // кнопка удаления
  const removeItem = (item) => {
    // удаляем товар через функцию из локала
    handleStorageUpdate(item, 'military', false);
    const local = localStorage.getItem('military');
    if (local.length > 0) {
      const { updatedProducts, total } = processProducts(data, local, userData);
      setItemList(updatedProducts);
      setSum(total);
    } else {
      setItemList(false);
    }
  };

  return (
    <div className="basket">
      <div className="basket__title">Ваш кошик</div>
      {itemList && itemList.length > 0 ? (
        <ul className="basket__list">
          {itemList.map((item, index) => (
            <BasketItem key={index} item={item} userData={userData} removeItem={() => removeItem(item)} addItem={() => handleStorageUpdate(item.id)} />
          ))}
        </ul>
      ) : (
        <div className="basket__list-empty">схоже у вашому кошику пусто</div>
      )}
      {itemList && itemList.length > 0 && (
        <div className="basket__cost">
          ₴ {sum}
          <Link to={'/payment'} onClick={() => setActiveWindow(false)} className="basket__cost-button">
            оформити замовлення
          </Link>
        </div>
      )}
    </div>
  );
}
