import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../Context';
import Rating from '@mui/material/Rating';
import { styled } from '@mui/material/styles';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { Alert, CircularProgress, Snackbar } from '@mui/material';
import { handleStorageUpdate } from '../../functions/AddBasket';
import { collectionArray } from '../../pages/Admin/Setting/Setting-info';
import { getTranslation } from '../../functions/Translation';
import { db } from '../../firebase';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { nanoid } from 'nanoid';
import './Product.css';
import Views from '../../components/Views/Views';
import { isVideo } from '../../functions/IsVideo';
import { useMediaQuery } from 'react-responsive';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#86C232', // Колір заповнених зірочок
  },
  '& .MuiRating-iconHover': {
    color: '#61892f', // Колір зірочок при наведенні
  },
  '& .MuiRating-iconEmpty': {
    color: '#86C232', // Колір пустих зірочок
  },
});

export default function Product() {
  const { setActiveWindow, data, setShowModal, isAuthenticated, userData, user } = useContext(Context);
  const location = useLocation();
  const navigate = useNavigate();

  const isIphone = useMediaQuery({ maxWidth: 576 });

  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Месяцы начинаются с 0
  const year = currentDate.getFullYear();

  const addLeadingZero = (num) => (num < 10 ? `0${num}` : num);

  // const searchParams = new URLSearchParams(location.search);

  const itemId = location.pathname.slice(1);

  // const itemId = searchParams.get('id');

  const [product, setProduct] = useState(false);
  const [colors, setColors] = useState(false);
  const [activeColor, setActiveColor] = useState(0);
  const [size, setSize] = useState(false);
  // проверка на размеры
  const [audit, setAudit] = useState(false);
  const [mainProduct, setMainProduct] = useState(false);
  const [starRating, setStarRating] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedComments, setSelectedComments] = useState([]);
  const commentsPerPage = 3;
  const [comment, setComment] = useState({
    star: 5,
  });

  // ------------------------------- пока грузится видео -------------------------------
  const [loading, setLoading] = useState(true);

  const handleVideoLoadStart = () => {
    setLoading(true); // Видео начало загружаться
  };

  const handleVideoLoadedData = () => {
    setLoading(false); // Видео загрузилось
  };

  // ------------------------------- листание фото -------------------------------

  const [currentIndex, setCurrentIndex] = useState(0);
  const [openAllFoto, setOpenAllFoto] = useState(4);

  const handleImageChange = (direction) => {
    if (direction === 'next') {
      setCurrentIndex((prevIndex) => (prevIndex === product.img.length - 1 ? 0 : prevIndex + 1));
    } else if (direction === 'prev') {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? product.img.length - 1 : prevIndex - 1));
    }
  };

  const setMainImg = (index) => {
    setCurrentIndex(index);
  };

  // ------------------------------- повідомленя -------------------------------

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMassage, setAlertMessage] = useState(false);

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const handleChange = (type) => (event, newValue) => {
    setComment((prevComment) => ({
      ...prevComment,
      [type]: type === 'star' ? newValue : event.target.value,
      date: `${addLeadingZero(day)}.${addLeadingZero(month)}.${year}`,
      id: nanoid(),
      user: userData.firstName,
    }));
  };

  const sendComment = async () => {
    const newCommentId = comment.id;

    const comments = product.comments ? { ...product.comments, [newCommentId]: comment } : { [newCommentId]: comment };
    const frankDocRef = await doc(db, 'data', product.id);

    await updateDoc(frankDocRef, {
      comments: comments,
    });

    setProduct((prevProduct) => ({
      ...prevProduct,
      comments: comments,
    }));

    const averageStarRating = Object.values(comments).reduce((sum, item) => sum + item.star, 0) / Object.values(comments).length;
    setStarRating(averageStarRating);

    setComment({
      star: 5,
      review: '',
      date: '',
      user: userData.firstName,
    });

    setCurrentPage(1);
  };

  const sendFavourite = async () => {
    if (isAuthenticated && user.emailVerified) {
      const frankDocRef = doc(db, 'users', user.uid);

      // Получаем текущий документ пользователя
      const docSnapshot = await getDoc(frankDocRef);

      if (docSnapshot.exists()) {
        // Получаем текущий список избранных товаров или создаем новый, если его нет
        const currentFavourites = docSnapshot.data().favourite || [];

        // Проверяем, есть ли уже product.id в списке избранных товаров
        if (!currentFavourites.includes(product.id)) {
          // Добавляем новый product.id к текущему списку избранных товаров
          const updatedFavourites = [...currentFavourites, product.id];

          // Обновляем документ пользователя с обновленным списком избранных товаров
          await updateDoc(frankDocRef, {
            favourite: updatedFavourites,
          });

          setAlertMessage('Додано');
          setOpenAlert(true);
        } else {
          setAlertMessage('Товар уже в обраному');
          setOpenAlert(true);
        }
      }
    } else {
      setShowModal(true);
      document.body.style.overflow = 'hidden';
    }
  };

  useEffect(() => {
    if (data && itemId) {
      const filteredProduct = data.filter((item) => item.id === itemId);

      if (filteredProduct.length === 0) {
        navigate('/not-found');
        return;
      }

      setProduct(filteredProduct[0]);
      const filteredColors = data.filter((item) => item.title === filteredProduct[0].title && item.color !== filteredProduct[0].color && item.img.length > 0);
      setColors(filteredColors);

      setMainProduct(filteredProduct[0]);
      setActiveColor(0);
      setOpenAllFoto(4);
      if (filteredProduct[0].comments) {
        const commentsArray = Object.values(filteredProduct[0].comments);
        setStarRating(commentsArray.reduce((sum, item) => sum + item.star, 0) / commentsArray.length);
      }

      // Запись ID в локальное хранилище
      let currentViews = JSON.parse(localStorage.getItem('military-views')) || [];

      if (!currentViews.includes(itemId)) {
        // Проверка на наличие ID
        if (currentViews.length >= 4) {
          currentViews.shift(); // Удаляем первый элемент
        }

        currentViews.push(itemId); // Добавляем новый ID в конец массива
        localStorage.setItem('military-views', JSON.stringify(currentViews));
      }
    }
    // eslint-disable-next-line
  }, [data, itemId]);

  useEffect(() => {
    if (product && product.comments) {
      const commentsArray = Object.values(product.comments);
      setTotalPages(Math.ceil(commentsArray.length / commentsPerPage));
      const startIndex = (currentPage - 1) * commentsPerPage;
      setSelectedComments(commentsArray.reverse().slice(startIndex, startIndex + commentsPerPage));
    }
  }, [product, currentPage, commentsPerPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleColorClick = (item, index) => {
    setProduct(item);
    setActiveColor(index);
    setCurrentIndex(0);
    setOpenAllFoto(4);
    if (item.comments) {
      const commentsArray = Object.values(item.comments);
      setStarRating(commentsArray.reduce((sum, item) => sum + item.star, 0) / commentsArray.length);
    } else {
      setStarRating(0);
    }

    setComment({
      star: 0,
    });

    setCurrentPage(1);

    const newUrl = `/${item.id}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
  };

  return product ? (
    <section name="foto" className="product">
      <Snackbar open={openAlert} autoHideDuration={2000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert onClose={handleAlertClose} severity="success" variant="filled" sx={{ width: '100%', backgroundColor: '#61892f' }}>
          {alertMassage}
        </Alert>
      </Snackbar>
      {!isIphone && (
        <ul className="category__buttons-links">
          <li>
            <RouterLink to={'/'}>Головна</RouterLink>
          </li>
          <li>/</li>
          <li>
            <RouterLink to={`/${product.collection}`}>{getTranslation(collectionArray, product.collection)}</RouterLink>
          </li>
          <li>/</li>
          {product.category && (
            <li>
              <RouterLink to={`/${product.collection}?category=${product.category}`}>{product.category.charAt(0).toUpperCase() + product.category.slice(1)}</RouterLink>
            </li>
          )}
          {product.category && <li>/</li>}
          <li className="category__buttons-links-transform">{product.title.charAt(0).toUpperCase() + product.title.slice(1)}</li>
        </ul>
      )}

      <div className="product__wrap">
        <div className="product__galery-desc-wrap">
          <div className="product__galery-big-img">
            {isVideo(product.img[currentIndex]) ? (
              <img src={product.img[currentIndex]} alt={product.title} />
            ) : (
              <>
                {loading && (
                  <div className="product__galery-big-img-loading">
                    <CircularProgress color="inherit" />
                  </div>
                )}
                <video
                  muted
                  autoPlay
                  preload="auto"
                  loop
                  playsInline
                  controls={false}
                  poster={product.img[0]}
                  onLoadStart={handleVideoLoadStart} // Когда видео начинает загружаться
                  onLoadedData={handleVideoLoadedData} // Когда видео загружено
                >
                  <source src={product.img[currentIndex]} type="video/mp4" />
                </video>
              </>
            )}
            <button onClick={() => handleImageChange('prev')} className="product__galery-big-img-left">
              <div className="product__galery-big-img-arrow product__galery-big-img-arrow-md">
                <img className="img-contain" src="./icon/arrow-img.svg" alt="стрілка вліво" />
              </div>
            </button>
            <button onClick={() => handleImageChange('next')} className="product__galery-big-img-right">
              <div className="product__galery-big-img-arrow">
                <img className="img-contain" src="./icon/arrow-img.svg" alt="стрілка вправо" />
              </div>
            </button>
          </div>

          <div className="product__galery-list">
            {product.img.slice(0, !isIphone ? openAllFoto : product.img.length).map((item, index) =>
              !isIphone ? (
                <ScrollLink
                  to="foto"
                  smooth={true}
                  duration={500}
                  offset={-30}
                  key={index}
                  onClick={() => setMainImg(index)}
                  className={`product__galery-list-item ${currentIndex === index ? 'product__galery-list-item-active' : ''} ${
                    !isVideo(item) ? 'product__galery-list-item-video-border' : ''
                  }`}
                >
                  <img className={!isVideo(item) ? 'product__galery-list-item-video' : ''} src={!isVideo(item) ? './icon/video.svg' : item} alt="product" />
                </ScrollLink>
              ) : (
                <div key={index} onClick={() => setMainImg(index)} className={`product__galery-list-item ${currentIndex === index ? 'product__galery-list-item-active' : ''}`}>
                  <img className={!isVideo(item) ? 'product__galery-list-item-video' : ''} src={!isVideo(item) ? './icon/video.svg' : item} alt="product" />
                </div>
              ),
            )}
            {openAllFoto < 5 && product.img.length > 4 && (
              <button onClick={() => setOpenAllFoto(product.img.length)} type="button" className="product__galery-list-button">
                + ще {product.img.length - 4}
              </button>
            )}
          </div>
        </div>
        <div className="product__info-review-wrap">
          <div className="product__info-review-wra-line"></div>
          <p className="product__info-manufacturer">{product.category}</p>
          <p className="product__info-title">{product.title}</p>

          <div className="product__info-star-wrap">
            <StyledRating name="read-only" value={starRating} readOnly />
            <p className="product__info-star-count">
              {product.comments && Object.keys(product.comments).length > 0 ? `${Object.keys(product.comments).length} відгуків` : 'немає відгуків'}
            </p>
          </div>

          <p className="product__info-price">
            {product.priceSale && !userData?.opt && product.priceSale !== '' ? (
              <>
                {Math.floor(product.price * (1 - product.priceSale / 100))} ГРН.<span className="product__info-price-sale">{product.price} ГРН.</span>
              </>
            ) : userData?.opt > 0 ? (
              <>
                {Math.floor(product.price * (1 - userData.opt / 100))} ГРН. <span className="product__info-price-sale">{product.price} ГРН.</span>
              </>
            ) : (
              <>{product.price} ГРН.</>
            )}
          </p>
          <p className="product__info-delivery">Доставка протягом 4-7 днів</p>

          <div className="product__info-basket-btn-wrapper">
            <button
              type="button"
              onClick={() => {
                if ((product.size && !product.size.includes(',')) || !product.size || size) {
                  const updatedProduct = {
                    id: product.id,
                    size: !product.size || product.size.includes(',') ? size : product.size,
                  };
                  handleStorageUpdate(updatedProduct, 'military', true);
                  setActiveWindow('basket');
                } else {
                  setAudit(true);
                  setTimeout(() => {
                    setAudit(false);
                  }, 1000);
                }
                if (isIphone) window.scrollTo(0, 0);
              }}
              className="product__info-basket-btn"
            >
              ДО КОШИКУ
            </button>

            <button type="button" onClick={() => sendFavourite()} className="product__info-basket-btn-favorite">
              ДО ОБРАННОГО
            </button>
          </div>

          {product.size && (
            <div className="product__info-size-wrap">
              <p className="product__info-size-text">РОЗМІР:</p>
              <ul className="product__info-size-items">
                {product.size.includes(',') ? (
                  product.size.split(',').map((item, index) => (
                    <li key={index}>
                      <button
                        className={`${item === size ? 'product__info-size-items-active' : ''} ${audit ? 'product__info-size-items-audit' : ''}`}
                        onClick={() => setSize(item)}
                      >
                        {item.trim()}
                      </button>
                    </li>
                  ))
                ) : (
                  <li key={0}>
                    <button className="product__info-size-items-active">{product.size}</button>
                  </li>
                )}
              </ul>
            </div>
          )}

          <div className="product__info-color-wrap">
            <p className="product__info-color-text">КОЛІР:</p>
            <ul className="product__info-color-list">
              <li
                onClick={() => handleColorClick(mainProduct, 0)}
                className={`product__info-color-list-item ${mainProduct.color} ${activeColor === 0 ? 'product__info-color-list-item-active' : ''}`}
              ></li>

              {colors &&
                colors.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => handleColorClick(item, index + 1)}
                    className={`product__info-color-list-item ${item.color} ${activeColor === index + 1 ? 'product__info-color-list-item-active' : ''}`}
                  ></li>
                ))}
            </ul>
          </div>
          <div className="product__info-description-wrap">
            <p className="product__info-description-title">опис:</p>
            <div className="product__info-description-text">{product.description}</div>
          </div>
        </div>
      </div>

      <div className="product__desc">
        <div className="product__desc-text">
          <p className="product__desc-title">характеристики</p>
          <p className="product__desc-list">{product.characteristic}</p>
        </div>

        <div name="comments" className="product__review">
          <div className="product__review-top-wrap">
            <p className="product__review-top-count">{product.comments ? `Відгуки покупців: (${Object.keys(product.comments).length})` : 'Відгуки покупців (0)'}</p>
            {!isAuthenticated && (
              <button onClick={() => setShowModal(true)} className="product__review-top-btn">
                НАПИСАТИ ВІДГУК
              </button>
            )}
          </div>
          <ul className="product__review-list">
            {isAuthenticated && user.emailVerified && (
              <li className="product__review-list-item">
                <div className="product__review-list-item-top">
                  {userData && <p className="product__review-list-item-top-user">{userData.firstName}</p>}
                  <p className="product__review-list-item-top-date">
                    {addLeadingZero(day)}.{addLeadingZero(month)}.{year}
                  </p>
                </div>
                <StyledRating value={comment.star} onChange={handleChange('star')} />
                <textarea onChange={handleChange('review')} rows={2} className="product__review-list-item-input" type="text" placeholder="Ваш коментар" value={comment.review} />
              </li>
            )}

            {isAuthenticated && user.emailVerified && (
              <button disabled={!comment.review || comment.star === null} onClick={() => sendComment()} className="product__review-top-btn">
                надіслати відгук
              </button>
            )}

            {selectedComments.map((item, index) => (
              <li key={index}>
                <div className="product__review-list-item">
                  <div className="product__review-list-item-top">
                    <p className="product__review-list-item-top-user">{item.user}</p>
                    <p className="product__review-list-item-top-date">{item.date}</p>
                  </div>
                  {item.star > 0 && <StyledRating name="read-only" value={item.star} readOnly />}
                  {item.review && <p className="product__review-list-item-review">{item.review}</p>}
                </div>

                {item.reply && (
                  <div className="product__review-list-item-ansver">
                    <img className="product__review-list-item-ansver-arrow" src="../icon/ansver.svg" alt="arrow" />
                    <div className="product__review-list-item-ansver-wrapper">
                      <p className="product__review-list-item-top-user">Адміністратор</p>
                      <p className="product__review-list-item-review">{item.reply}</p>
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>

          {product.comments && Object.keys(product.comments).length > commentsPerPage && (
            <div className="product__pagination">
              {Array.from({ length: totalPages }, (_, index) => (
                <ScrollLink
                  to="comments"
                  smooth={true}
                  duration={500}
                  offset={-50}
                  key={index}
                  className={`${currentPage === index + 1 ? 'product__pagination-active' : ''}`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </ScrollLink>
              ))}
            </div>
          )}
        </div>
      </div>

      <Views />
    </section>
  ) : (
    <div className="load">
      <CircularProgress color="inherit" />
    </div>
  );
}
