export const text = [
  { to: '/sale', text: 'знижки' },
  { to: '/new', text: 'новинки' },
  { to: '/about-us', text: 'про нас' },
  { to: '/contacts', text: 'контакти' },
];

export const menuLinks = [
  { to: '/clothes', text: 'одяг' },
  { to: '/footwear', text: 'взуття' },
  { to: '/tactical-equipment', text: 'тактичне спорядження' },
  { to: '/ballistic-protection', text: 'балістичний захист' },
  { to: '/equipment', text: 'спорядження та екіпірування' },
  { to: '/weapon-accessories', text: 'збройові аксесуари' },
];

