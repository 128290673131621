import React, { useContext, useState, useEffect } from 'react';
import { firstList, secondList, formFields } from './about-us-text';
import axios from 'axios';
import { Loader } from '../../components/Loader/Loader';
import { Alert, Snackbar } from '@mui/material';
import { Context } from '../../Context';

import './AboutUs.css';

export default function AboutUs() {
  const { isAuthenticated, userData, setShowModal, user } = useContext(Context);
  const [formData, setFormData] = useState({
    firstName: '',
    secondName: '',
    phone: '',
    email: '',
    message: '',
    checkbox: false,
  });
  const [isEditing, setIsEditing] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [checkboxFlag, setCheckboxFlag] = useState(false);

  // вспливаючі повідомлення

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMassage, setAlertMessage] = useState(false);

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  // перевірка на залогін + підкидаємо данні
  useEffect(() => {
    if (userData && user.emailVerified) {
      const { firstName, secondName, email, phone } = userData;
      setFormData({
        secondName: secondName,
        firstName: firstName,
        phone: phone,
        email: email,
      });
      setIsEditing(false);
    }
  }, [userData, user]);

  // поля форми
  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const formedTelegramMessage = () => {
    const { firstName, secondName, phone, email, message } = formData;
    let isLogin;
    if (isAuthenticated) {
      isLogin = 'зареєстрований';
    } else {
      isLogin = 'не зареєстрований';
    }
    return {
      Користувач: isLogin,
      "ім'я": firstName + ' ' + secondName,
      телефон: phone,
      email,
      '=-=-=-=-=-': '-=-=-=-=-=-',
      Повідомлення: message,
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const textMessage = formedTelegramMessage();
    await axios.post('https://military-backend.onrender.com/telegram-message', textMessage);
    setCheckboxFlag(false);
    setFormData({ firstName: '', secondName: '', phone: '', email: '', message: '', checkbox: false });
    setIsLoading(false);
    setAlertMessage('Дякуємо за відгук!');
    setOpenAlert(true);
  };

  const handleActiveModalWindow = () => {
    setShowModal(true);
  };

  return (
    <>
      {isLoading && <Loader />}
      <Snackbar open={openAlert} autoHideDuration={2000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert onClose={handleAlertClose} severity="success" variant="filled" sx={{ width: '100%', backgroundColor: '#61892f' }}>
          {alertMassage}
        </Alert>
      </Snackbar>
      <section className="about-us">
        <div className="about-us__section">
          <div className="about-us__section-box-text">
            <p className="about-us__section-text--small">Про нас</p>
            <p className="about-us__section-title">Ми працюємо на результат!</p>
          </div>
          <div className="about-us__section-text--big">
            «ЗАХИСНИК» — це торгівельна мережа товарів власного виробництва, заснована в місті Запоріжжя весною 2022 року, після початку повномасштабного вторгнення рф на територію
            України. У перші важкі дні нашим захисникам і захисницям, які стали на захист своєї Батьківщини, гостро не вистачало одягу та військової амуніції. Ми почали працювати
            над створенням комфортного, зручного одягу і тактичної амуніції для українських військових. Компанія «ЗАХИСНИК» докладає всіх зусиль, щоб нашим захисникам і захисницям
            було зручно, тепло і комфортно. Кожен виріб створюється з любов’ю та ретельно перевіряється на якість, починаючи від фурнітури і закінчуючи готовим продуктом. Ми
            прагнемо, щоб наші моделі, розроблені нами, були зручні навіть в екстремальних умовах і приносили естетичне задоволення.
          </div>
        </div>
        <div className="about-us__section-img-big">
          <img src="./img/about-us-big.webp" alt="military" />
        </div>
        <div className="about-us__section-center">
          <div className="about-us__section-center--left">
            <p className="about-us__section-center-title">У наших магазинах представлений широкий асортимент товарів власного виробництва:</p>
            <div className="about-us__section-center-list-wrap">
              <div className="about-us__section-center-list-wrap--one">
                <p className="about-us__section-center-list-title">Одяг:</p>
                <ul className="about-us__section-center-list">
                  {firstList.map((text, index) => {
                    return (
                      <li className="about-us__section-center-list-item" key={index}>
                        {text}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="about-us__section-center-list-wrap--one">
                <p className="about-us__section-center-list-title">Амуніція:</p>
                <ul className="about-us__section-center-list">
                  {secondList.map((text, index) => {
                    return (
                      <li className="about-us__section-center-list-item" key={index}>
                        {text}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="about-us__section-center-img--small">
            <img src="./img/about-us-small.webp" alt="military" />
          </div>
        </div>
        <p className="about-us__text--bottom">Також у нас представлений кевлар 1А класу з протоколом випробувань. Ви можете замовити виріб за вашими розмірами.</p>
        <div className="about-us__section-communication">
          <div className="about-us__section-communication-left">
            <p className="about-us__section-communication-left-title">Залиште свій відгук про нас!</p>
            <p className="about-us__section-communication-left-text">Або зв’яжіться з нами напряму.</p>
            <div class="about-us__section-communication-left-list">
              <div class="about-us__section-communication-left-list-item">
                <div class="about-us__section-communication-left-list-item-img">
                  <img src="./icon/about-us-email.svg" alt="icon" class="img-contain" />
                </div>
                <ul class="about-us__section-communication-left-list-item-textList--wrap">
                  <li class="about-us__section-communication-left-list-item-textList">
                    <a href="mailto:hello@relume.io">hello@relume.io</a>
                  </li>
                </ul>
              </div>
              <div class="about-us__section-communication-left-list-item">
                <div class="about-us__section-communication-left-list-item-img">
                  <img src="./icon/about-us-phone.svg" alt="icon" class="img-contain" />
                </div>
                <ul class="about-us__section-communication-left-list-item-textList--wrap">
                  <li class="about-us__section-communication-left-list-item-textList">
                    <a href="tel:+38 (095) 873-33-73">+38 (095) 873-33-73</a>
                  </li>
                  <li class="about-us__section-communication-left-list-item-textList">
                    <a href="tel:+38 (095) 316-30-15">+38 (095) 316-30-15</a>
                  </li>
                  <li class="about-us__section-communication-left-list-item-textList">
                    <a href="tel:+38 (066) 289-67-81">+38 (066) 289-67-81</a>
                  </li>
                  <li class="about-us__section-communication-left-list-item-textList">
                    <a href="tel:+38 (095) 316-30-15">+38 (095) 316-30-15</a>
                  </li>
                </ul>
              </div>
              <div class="about-us__section-communication-left-list-item">
                <div class="about-us__section-communication-left-list-item-img">
                  <img src="./icon/about-us-location.svg" alt="icon" class="img-contain" />
                </div>
                <ul class="about-us__section-communication-left-list-item-textList--wrap">
                  <li class="about-us__section-communication-left-list-item-textList">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.google.com/maps/place/%D0%9E%D0%B1%D0%BE%D0%BB%D0%BE%D0%BD%D1%81%D1%8C%D0%BA%D0%B8%D0%B9+%D0%BF%D1%80%D0%BE%D1%81%D0%BF%D0%B5%D0%BA%D1%82,+51%D0%B2,+%D0%9A%D0%B8%D1%97%D0%B2,+02000/@50.524506,30.49891,1820m/data=!3m1!1e3!4m6!3m5!1s0x40d4d24415fc0001:0x197604383987eb2c!8m2!3d50.524907!4d30.4979606!16s%2Fg%2F11vq9mn48n?hl=uk&entry=ttu&g_ep=EgoyMDI0MDkwNC4wIKXMDSoASAFQAw%3D%3D"
                    >
                      Київ, проспект Оболонський 51 В
                    </a>
                  </li>
                  <li class="about-us__section-communication-left-list-item-textList">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.google.com/maps?ll=47.811044,35.183552&z=16&t=m&hl=uk&gl=UA&mapclient=embed&q=%D0%BF%D1%80%D0%BE%D1%81%D0%BF%D0%B5%D0%BA%D1%82+%D0%A1%D0%BE%D0%B1%D0%BE%D1%80%D0%BD%D0%B8%D0%B9,+44+%D0%97%D0%B0%D0%BF%D0%BE%D1%80%D1%96%D0%B6%D0%B6%D1%8F+%D0%97%D0%B0%D0%BF%D0%BE%D1%80%D1%96%D0%B7%D1%8C%D0%BA%D0%B0+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C+69000"
                    >
                      Запоріжжя, пр. Соборний 44
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.google.com/maps/place/%D0%BF%D1%80%D0%BE%D1%81%D0%BF%D0%B5%D0%BA%D1%82+%D0%A1%D0%BE%D0%B1%D0%BE%D1%80%D0%BD%D0%B8%D0%B9,+143,+%D0%97%D0%B0%D0%BF%D0%BE%D1%80%D1%96%D0%B6%D0%B6%D1%8F,+%D0%97%D0%B0%D0%BF%D0%BE%D1%80%D1%96%D0%B7%D1%8C%D0%BA%D0%B0+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+69000/@47.8270421,35.1618512,961m/data=!3m2!1e3!4b1!4m6!3m5!1s0x40dc5dffd1d88435:0x5fb106cc0d32d076!8m2!3d47.8270421!4d35.1644315!16s%2Fg%2F11c24fkq2v?hl=uk&entry=ttu&g_ep=EgoyMDI0MDkwNC4wIKXMDSoASAFQAw%3D%3D"
                    >
                      пр. Соборний 143
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.google.com/maps/place/%D0%BF%D1%80%D0%BE%D1%81%D0%BF%D0%B5%D0%BA%D1%82+%D0%A1%D0%BE%D0%B1%D0%BE%D1%80%D0%BD%D0%B8%D0%B9,+172,+%D0%97%D0%B0%D0%BF%D0%BE%D1%80%D1%96%D0%B6%D0%B6%D1%8F,+%D0%97%D0%B0%D0%BF%D0%BE%D1%80%D1%96%D0%B7%D1%8C%D0%BA%D0%B0+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+69000/@47.8415398,35.1326623,961m/data=!3m2!1e3!4b1!4m6!3m5!1s0x40dc67efe8a6a097:0x278d03c9b5c4dfcd!8m2!3d47.8415398!4d35.1352426!16s%2Fg%2F11tpbqx2x4?hl=uk&entry=ttu&g_ep=EgoyMDI0MDkwNC4wIKXMDSoASAFQAw%3D%3D"
                    >
                      пр. Соборний 172
                    </a>
                  </li>
                  <li class="about-us__section-communication-left-list-item-textList">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.google.com/maps/place/%D0%BF%D1%80%D0%BE%D1%81%D0%BF%D0%B5%D0%BA%D1%82+%D0%A1%D0%BE%D0%B1%D0%BE%D1%80%D0%BD%D0%B8%D0%B9,+26,+%D0%97%D0%B0%D0%BF%D0%BE%D1%80%D1%96%D0%B6%D0%B6%D1%8F,+%D0%97%D0%B0%D0%BF%D0%BE%D1%80%D1%96%D0%B7%D1%8C%D0%BA%D0%B0+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+69000/@47.8088853,35.1836953,961m/data=!3m2!1e3!4b1!4m6!3m5!1s0x40dc5e7192a062d3:0xe8bcc7abce39e16e!8m2!3d47.8088853!4d35.1862756!16s%2Fg%2F1tc_pjv6?hl=uk&entry=ttu&g_ep=EgoyMDI0MDkwNC4wIKXMDSoASAFQAw%3D%3D"
                    >
                      Запоріжжя, пр. Соборний 26
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.google.com/maps/place/121B,+%D0%9A%D0%BE%D1%81%D0%BC%D1%96%D1%87%D0%BD%D0%B0+%D0%B2%D1%83%D0%BB%D0%B8%D1%86%D1%8F,+121%D0%91,+%D0%97%D0%B0%D0%BF%D0%BE%D1%80%D1%96%D0%B6%D0%B6%D1%8F,+%D0%97%D0%B0%D0%BF%D0%BE%D1%80%D1%96%D0%B7%D1%8C%D0%BA%D0%B0+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+69000/@47.788626,35.2186332,962m/data=!3m2!1e3!4b1!4m6!3m5!1s0x40dc5e9433c6ee0f:0x60d2b3ac8148c1ec!8m2!3d47.788626!4d35.2212135!16s%2Fg%2F1tsr1qpf?hl=uk&entry=ttu&g_ep=EgoyMDI0MDkwNC4wIKXMDSoASAFQAw%3D%3D"
                    >
                      вул. Космічна 121-Б
                    </a>
                  </li>
                  <li class="about-us__section-communication-left-list-item-textList">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.google.com/maps/place/%D0%92%D1%83%D0%BB%D0%B8%D1%86%D1%8F+%D0%93%D0%BE%D1%80%D1%8C%D0%BA%D0%BE%D0%B3%D0%BE,+45,+%D0%9F%D0%BE%D0%BA%D1%80%D0%BE%D0%B2%D1%81%D1%8C%D0%BA%D0%B5,+%D0%94%D0%BD%D1%96%D0%BF%D1%80%D0%BE%D0%BF%D0%B5%D1%82%D1%80%D0%BE%D0%B2%D1%81%D1%8C%D0%BA%D0%B0+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+53601/@47.9840167,36.2291603,958m/data=!3m1!1e3!4m5!3m4!1s0x40ddd14dda9f56eb:0xfe88c01bcc45f185!8m2!3d47.9846271!4d36.2286882?entry=ttu&g_ep=EgoyMDI0MDkwNC4wIKXMDSoASAFQAw%3D%3D"
                    >
                      смт. Покровське, вул. Горького, 45 (в будівлі автовокзалу)
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.google.com/maps/place/%D0%92%D1%83%D0%BB%D0%B8%D1%86%D1%8F+%D0%93%D0%BE%D1%80%D1%8C%D0%BA%D0%BE%D0%B3%D0%BE,+45,+%D0%9F%D0%BE%D0%BA%D1%80%D0%BE%D0%B2%D1%81%D1%8C%D0%BA%D0%B5,+%D0%94%D0%BD%D1%96%D0%BF%D1%80%D0%BE%D0%BF%D0%B5%D1%82%D1%80%D0%BE%D0%B2%D1%81%D1%8C%D0%BA%D0%B0+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+53601/@47.9840167,36.2291603,958m/data=!3m1!1e3!4m5!3m4!1s0x40ddd14dda9f56eb:0xfe88c01bcc45f185!8m2!3d47.9846271!4d36.2286882?entry=ttu&g_ep=EgoyMDI0MDkwNC4wIKXMDSoASAFQAw%3D%3D"
                    >
                      вул. Горького, 62
                    </a>
                  </li>
                  <li class="about-us__section-communication-left-list-item-textList">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.google.com/maps/place/%D0%95%D0%B2%D1%80%D0%BE%D0%9C%D0%B5%D0%B4%D0%9E%D0%BF%D1%82%D0%B8%D0%BA%D0%B0+%D0%95%D0%B2%D1%80%D0%BE%D0%BF%D0%B5%D0%B9%D1%81%D0%BA%D0%B0%D1%8F,+22+(%D0%B7%D0%B4%D0%B0%D0%BD%D0%B8%D0%B5+%D0%B1%D0%B8%D0%B1%D0%BB%D0%B8%D0%BE%D1%82%D0%B5%D0%BA%D0%B8)./@48.2757201,37.178288,121m/data=!3m1!1e3!4m6!3m5!1s0x40de3978fb0ec353:0x81c7d7d20b4194d4!8m2!3d48.2750642!4d37.1766264!16s%2Fg%2F11nx1z3_lw?hl=ru-RU&entry=ttu&g_ep=EgoyMDI0MDkwNC4wIKXMDSoASAFQAw%3D%3D"
                    >
                      м. Покровськ, вул. Европейська, 22
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <form className="about-us__form" onSubmit={handleSubmit}>
            <div className="about-us__form-inputs">
              {formFields.map((field) => (
                <input
                  className="about-us__form-input"
                  key={field.name}
                  type={field.type}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                  disabled={!isEditing}
                  placeholder={field.label}
                  required
                />
              ))}
            </div>
            {!isAuthenticated && (
              <button className="about-us__form-button" type="button" onClick={handleActiveModalWindow}>
                Увійти в особистий кабінет
              </button>
            )}

            <textarea
              className="about-us__form-input-big"
              type="text"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Напишіть ваш відгук або побажання"
              required
            />
            <label className={`about-us__form-checkbox-label ${checkboxFlag ? 'about-us__form-checkbox-label--empty' : ''}`} htmlFor="checkbox">
              <input className="about-us__form-checkbox" type="checkbox" name="checkbox" checked={formData.checkbox} onChange={handleChange} id="checkbox" required />
              <div className="about-us__form-checkbox-castom--wrap">
                <div className="about-us__form-checkbox-castom"></div>
              </div>
              Я погоджуюсь з політикою конфіденційності
            </label>
            <button type="submit" className="about-us__form-submit-button">
              Відправити
            </button>
          </form>
        </div>
      </section>
    </>
  );
}
