// export const processProducts = (data, local) => {
//   const idArray = JSON.parse(local);
//   const countMap = {};
//   idArray.forEach((id) => {
//     countMap[id] = (countMap[id] || 0) + 1;
//   });

//   const updatedProducts = data
//     .filter((product) => countMap[product.id])
//     .map((product) => ({
//       ...product,
//       quantity: countMap[product.id],
//     }));

//   // высчитываем всю сумму
//   const total = updatedProducts.reduce((acc, item) => {
//     const price = parseInt(
//       item.opt && item.opt > 0 ? item.price * (1 - item.opt / 100) : item.priceSale && item.priceSale !== '' ? item.price * (1 - item.priceSale / 100) : item.price,
//     );
//     return acc + price * item.quantity;
//   }, 0);

//   return { updatedProducts, total };
// };
// export const processProducts = (data, local) => {
//   // Преобразуем строку из localStorage в массив объектов
//   const productArray = JSON.parse(local);
//   const countMap = {};

//   // Создаем карту, где ключом будет id товара, а значением - количество этих товаров
//   productArray.forEach((item) => {
//     const id = item.id;
//     countMap[id] = countMap[id] || { count: 0, size: item.size };
//     countMap[id].count += 1;
//   });

//   // Обновляем массив товаров из данных
//   const updatedProducts = data
//     .filter((product) => countMap[product.id])
//     .map((product) => ({
//       ...product,
//       quantity: countMap[product.id].count,
//       size: countMap[product.id].size, // Перезаписываем size, если он есть в local
//     }));

//   // Высчитываем всю сумму
//   const total = updatedProducts.reduce((acc, item) => {
//     const price = parseInt(
//       item.opt && item.opt > 0 ? item.price * (1 - item.opt / 100) : item.priceSale && item.priceSale !== '' ? item.price * (1 - item.priceSale / 100) : item.price,
//     );
//     return acc + price * item.quantity;
//   }, 0);
//   console.log(updatedProducts);

//   return { updatedProducts, total };
// };

// export const processProducts = (data, local) => {
//   // Преобразуем строку из localStorage в массив объектов
//   const productArray = JSON.parse(local);

//   // Создаем карту, чтобы сохранить товары из data по id
//   const dataMap = new Map(data.map((product) => [product.id, product]));

//   // Создаем карту для подсчета количества товаров с одинаковым id и size
//   const countMap = {};

//   productArray.forEach((item) => {
//     const id = item.id;
//     const size = item.size || false;
//     const key = `${id}_${size}`;

//     // Проверяем, существует ли товар с таким id в данных из сервера
//     if (dataMap.has(id)) {
//       if (!countMap[key]) {
//         countMap[key] = { ...item, quantity: 0 };
//       }
//       countMap[key].quantity += 1;
//     }
//   });

//   // Преобразуем countMap в массив обновленных товаров
//   const updatedProducts = Object.values(countMap);

//   // Высчитываем всю сумму
//   const total = updatedProducts.reduce((acc, item) => {
//     const price = parseInt(
//       item.opt && item.opt > 0 ? item.price * (1 - item.opt / 100) : item.priceSale && item.priceSale !== '' ? item.price * (1 - item.priceSale / 100) : item.price,
//     );
//     return acc + price * item.quantity;
//   }, 0);

//   console.log(updatedProducts);

//   return { updatedProducts, total };
// };
export const processProducts = (data, local, userData) => {
  // Преобразуем строку из localStorage в массив объектов
  const productArray = JSON.parse(local);

  // Создаем карту для хранения товаров с обновленными размерами
  const updatedDataMap = new Map();

  productArray.forEach((item) => {
    const { id, size } = item;

    // Если товар с данным id существует в data
    if (updatedDataMap.has(id)) {
      const productList = updatedDataMap.get(id);
      productList.push({ ...productList[0], size });
      updatedDataMap.set(id, productList);
    } else {
      const productFromData = data.find((product) => product.id === id);
      if (productFromData) {
        updatedDataMap.set(id, [{ ...productFromData, size }]);
      }
    }
  });

  // Создаем карту для подсчета количества товаров с одинаковым id и size
  const countMap = {};

  updatedDataMap.forEach((productList) => {
    productList.forEach((product) => {
      const { id, size } = product;
      const key = `${id}_${size}`;

      if (!countMap[key]) {
        countMap[key] = { ...product, quantity: 0 };
      }
      countMap[key].quantity += 1;
    });
  });

  // Преобразуем countMap в массив обновленных товаров
  const updatedProducts = Object.values(countMap);

  // Высчитываем всю сумму
  const total = updatedProducts.reduce((acc, item) => {
    const price = Math.floor(
      userData?.opt ? item.price * (1 - userData.opt / 100) : item.priceSale && item.priceSale !== '' ? item.price * (1 - item.priceSale / 100) : item.price,
    );
    return acc + price * item.quantity;
  }, 0);

  return { updatedProducts, total };
};
