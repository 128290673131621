import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainRoute from './pages/MainRoute';
import { Context } from './Context';
import { auth, db } from './firebase';
import { getDocs, collection, doc, getDoc, query, orderBy } from 'firebase/firestore';
import DeleteAdmin from './pages/Admin/DeleteAdmin/DeleteAdmin';
// import Papa from 'papaparse';
// import axios from 'axios';

function App() {
  // кнопки администратора
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [admin, setAdmin] = useState(false);

  // лічильник корзини
  const [busketNumber, setBusketNumber] = useState([]);

  // сумма всех товаров
  const [sum, setSum] = useState(0);

  // данні з фаербейс
  const [data, setData] = useState(null);

  // // данные из CSV
  // const [csvData, setCsvData] = useState(null);

  // // товари фільровані
  // const [mainData, setMainData] = useState(null);

  // открытие окна
  const [activeWindow, setActiveWindow] = useState(false);

  // показать окно регистрации
  const [showModal, setShowModal] = useState(false);

  // дані юзеру
  const [userData, setUserData] = useState(null);

  // авторизація юзеру
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (activeWindow) {
      document.body.style.overflow = 'hidden'; // Блокируем прокрутку тела страницы, если меню открыто
    } else {
      document.body.style.overflow = 'auto'; // Восстанавливаем прокрутку тела страницы, если меню закрыто
    }
  }, [activeWindow]);

  const [selectedItems, setSelectedItems] = useState({});

  // аторизация пользователя
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        if (user.uid === 'iBITOieFZUdQd898p2OhWHKc9bF2' || user.uid === 'tATMTXSyfCW9POUYptb790f3p572' || user.uid === 'sxlDj6ux1IZpxtAxHl7DapZg9Gz1') {
          setAdmin(true);
        }
        setUser(user);
        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setUserData(docSnap.data());
        }
      }
      setIsAuthenticated(!!user); // Если пользователь авторизован, устанавливаем состояние isAuthenticated в true
    });
    return () => unsubscribe(); // Отписываемся от слушателя при размонтировании компонента
  }, []);

  // получение данных
useEffect(() => {
  const fetchData = async () => {
    try {
      // Создание запроса с сортировкой по дате
      const q = query(collection(db, 'data'), orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        // Преобразование документов в массив данных
        const newData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setData(newData);
        console.log(newData);
      }
    } catch (error) {
      console.error('Error getting products: ', error);
    }
  };

  fetchData();
}, []);

  // useEffect(() => {
  //   if (data && userData && userData.opt > 0) {
  //     const updatedData = data.map(product => ({
  //       ...product,
  //       opt: userData.opt,
  //     }));
  //     setData(updatedData);    
  //   }
  //   // eslint-disable-next-line
  // }, [userData]);

  // получение данных из CSV файла
  // useEffect(() => {
  //   const fetchCSV = async () => {
  //     try {
  //       const response = await axios.get('https://military-backend.onrender.com/military/csv');
  //       Papa.parse(response.data, {
  //         header: true,
  //         complete: (result) => {
  //           setCsvData(result.data);
  //           console.log(result.data);
  //         },
  //       });
  //     } catch (error) {
  //       console.error('Error fetching CSV: ', error);
  //     }
  //   };

  //   fetchCSV();
  // }, []);

  // фільтруєм товари з 1с
  // useEffect(() => {
  //   if (data && csvData) {
  //     let newObj = [];

  //     const cleanObject = (obj) => {
  //       const cleanedObj = {};
  //       for (let key in obj) {
  //         if (obj[key] !== '' && obj[key] !== null && obj[key] !== undefined) {
  //           cleanedObj[key] = obj[key];
  //         }
  //       }
  //       return cleanedObj;
  //     };

  //     data.filter((product) => {
  //       if (
  //         csvData.some((csvProduct) => {
  //           if (csvProduct[3447] === product.article) {
  //             // Переименовываем ключ " " на "price"
  //             if (csvProduct.hasOwnProperty(' ')) {
  //               csvProduct.price = csvProduct[' '];
  //               delete csvProduct[' '];
  //             }
  //             // Переименовываем ключ 14 на "quantity"
  //             if (csvProduct.hasOwnProperty(13)) {
  //               csvProduct.quantity = csvProduct[13];
  //               delete csvProduct[13];
  //             }

  //             const allKeys = Object.keys(product).concat(Object.keys(csvProduct));
  //             const combinedObject = {};
  //             allKeys.forEach((key) => {
  //               if (product.hasOwnProperty(key)) {
  //                 combinedObject[key] = product[key];
  //               }
  //               if (csvProduct.hasOwnProperty(key)) {
  //                 combinedObject[key] = csvProduct[key];
  //               }
  //             });

  //             // Добавляем opt, если пользователь существует и у него есть providerData с полем opt
  //             if (userData && userData.opt > 0) {
  //               combinedObject.opt = userData.opt;
  //             }

  //             // Очищаем объединенный объект
  //             const cleanedCombinedObject = cleanObject(combinedObject);
  //             newObj.push(cleanedCombinedObject);
  //             return true;
  //           }
  //           return false;
  //         })
  //       ) {
  //         return true;
  //       }
  //       return false;
  //     });

  //     console.log('newObj', newObj);
  //     setMainData(newObj);
  //   }
  // }, [data, csvData, userData]);

  // записываем корзину
  const military = localStorage.getItem('military');

  useEffect(() => {
    if (military) {
      const militaryNumbers = JSON.parse(military);
      setBusketNumber(militaryNumbers);
    }
  }, [military]);

  return (
    <Context.Provider
      value={{
        admin,
        isAuthenticated,
        setIsAuthenticated,
        busketNumber,
        data,
        setData,
        selectedItems,
        setSelectedItems,
        activeWindow,
        setActiveWindow,
        sum,
        setBusketNumber,
        setSum,
        showModal,
        setShowModal,
        userData,
        setUserData,
        user,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<MainRoute />} />
        </Routes>
        {isAuthenticated && admin && <DeleteAdmin />}
      </BrowserRouter>
    </Context.Provider>
  );
}

export default App;
