export const api_key_NP = '23fa6601f08c553d1487f0cabb99b02d';

export const inputs = [
  { name: 'lastName', label: 'Ваше прізвище', type: 'text' },
  { name: 'firstName', label: "Ваше ім'я", type: 'text' },
  { name: 'phone', label: 'Ваш телефон', type: 'tel' },
  { name: 'email', label: 'E-mail', type: 'email' },
];

export const deliveryBtn = [
  // { text: 'Безготівкова оплата', index: 1 },
  { text: 'Оплата під-час отримання товару', secondaryText: '(за тарифами перевізника)', index: 2 },
];

export const receiver = [
  { name: 'receiverSecondName', label: 'Прізвище', type: 'text' },
  { name: 'receivefirstName', label: "Ім'я", type: 'text' },
  { name: 'receiverPhone', label: 'Телефон', type: 'tel' },
];
