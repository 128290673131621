import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Item.css';

export default function Item({ item, userData }) {
  const navigate = useNavigate();

  return (
    <li className="item" onClick={() => navigate(`/${item.id}`)}>
      {!userData?.opt && item.newSale === 'new' && <div className="item-icon">Нове</div>}
      {!userData?.opt && item.newSale === 'sale' && <div className="item-icon item-icon-sale">- {item.priceSale} %</div>}
      {userData?.opt && <div className="item-icon">ОПТ</div>}
      <div className="item__img">
        <img src={item.img} alt={item.title} />
      </div>
      <div className="item__title">{item.title}</div>
      <div className="item__category">{item.category}</div>
      <div className="item__price">
        {item.priceSale && !userData?.opt && item.priceSale !== '' ? (
          <>
            ₴ {Math.floor(item.price * (1 - item.priceSale / 100))} <span className="item__price-sale">₴ {item.price}</span>
          </>
        ) : userData?.opt ? (
          <>
            ₴ {Math.floor(item.price * (1 - userData.opt / 100))} <span className="item__price-sale">₴ {item.price}</span>
          </>
        ) : (
          <>₴ {item.price}</>
        )}
      </div>
    </li>
  );
}
