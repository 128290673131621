import React, { useContext, useEffect, useState } from 'react';
import { db } from '../../../../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import Rating from '@mui/material/Rating';
import { styled } from '@mui/material/styles';
import { Context } from '../../../../Context';
import './Comments.css';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#86C232', // Колір заповнених зірочок
  },
  '& .MuiRating-iconHover': {
    color: '#61892f', // Колір зірочок при наведенні
  },
});

export default function Comments() {
  const [sortedComments, setSortedComments] = useState([]); // массив для всех комментариев
  const [openCommentAnsver, setOpenCommentAnsver] = useState(false);
  const [answer, setAnswer] = useState(''); // записываем ответ
  const { data } = useContext(Context);

  const allComments = () => {
    setOpenCommentAnsver(false);

    // Формируем массив всех комментариев с добавлением id товара
    let allComments = [];
    data.forEach((product) => {
      if (product.comments && Object.keys(product.comments).length > 0) {
        Object.values(product.comments).forEach((comment) => {
          allComments.push({
            ...comment,
            productId: product.id,
          });
        });
      }
    });

    // Сортируем комментарии по дате в порядке убывания
    allComments.sort((a, b) => {
      const dateA = new Date(a.date.split('.').reverse().join('-'));
      const dateB = new Date(b.date.split('.').reverse().join('-'));
      return dateB - dateA;
    });

    // Обновляем состояние
    setSortedComments(allComments);
  };

  useEffect(() => {
    allComments();
    // eslint-disable-next-line
  }, []);

  // отправление ответа
  const handleSendAnswer = async (item, productId) => {
    const filteredProducts = data.filter((product) => product.id === productId);

    const updatedProduct = { ...filteredProducts[0] };
    // // Находим комментарий, который нужно обновить
    const commentToUpdate = updatedProduct.comments[item.id];

    // Обновляем только поле reply у найденного комментария
    updatedProduct.comments[item.id] = {
      ...commentToUpdate,
      reply: answer,
    };

    // Обновляем документ товара в базе данных Firestore
    const productDocRef = doc(db, 'data', productId);
    await updateDoc(productDocRef, {
      comments: updatedProduct.comments,
    });

    setAnswer('');
    setOpenCommentAnsver(false);
    allComments();
  };

  // удаление комментария
  const handleDelete = async (item, productId, type) => {
    const filteredProducts = data.filter((product) => product.id === productId);

    const updatedProduct = { ...filteredProducts[0] };

    // Проверяем, существует ли комментарий с указанным item.id
    if (updatedProduct.comments[item.id]) {
      // Если тип 'comment', удаляем комментарий полностью
      if (type === 'comment') {
        delete updatedProduct.comments[item.id];
      } else {
        const commentToUpdate = updatedProduct.comments[item.id];
        delete commentToUpdate.reply;
        updatedProduct.comments[item.id] = commentToUpdate;
      }

      // Обновляем документ товара в базе данных Firestore
      const productDocRef = doc(db, 'data', productId);
      await updateDoc(productDocRef, {
        comments: updatedProduct.comments,
      });
    }
    allComments();
  };

  return (
    <div className="setting__item-wrapper">
      {sortedComments.map((item, index) => (
        <div className="setting__comment-wrapper" key={index}>
          <div className="setting__comment-item">
            <div className="setting__comment-item-border">{item.date}</div>
            <StyledRating name="read-only" value={item.star} readOnly />
            <div className="setting__comment-item-border">{item.user}</div>
            <div className="setting__comment-item-comment">{item.review}</div>
            <button
              onClick={() => {
                if (openCommentAnsver === index) {
                  setOpenCommentAnsver(false);
                } else {
                  setOpenCommentAnsver(index);
                }
              }}
              className="setting__comment-item-edit"
            >
              відповісти
            </button>

            <Link className="setting__comment-item-link" to={`/${item.productId}`}>
              i
            </Link>

            <button onClick={() => handleDelete(item, item.productId, 'comment')} className="setting__comment-item-delete">
              <img className="img-contain" src="/icon/delete.svg" alt="delete" />
            </button>
          </div>

          {item.reply && (
            <div className="setting__comment-item-reply">
              <div className="setting__comment-item-reply-name">відповідь</div>
              <div className="setting__comment-item-reply-comment">{item.reply}</div>
              <button onClick={() => handleDelete(item, item.productId)} className="setting__comment-item-delete">
                <img className="img-contain" src="/icon/delete.svg" alt="delete" />
              </button>
            </div>
          )}

          {openCommentAnsver === index && (
            <div className="setting__comment-item-input">
              <input
                value={answer}
                className="setting__comment-item-comment"
                type="text"
                placeholder="Додайте відповідь"
                onChange={(e) => {
                  setAnswer(e.target.value);
                }}
              />
              <button onClick={() => handleSendAnswer(item, item.productId)} className="setting__comment-item-edit">
                надіслати
              </button>
              <button onClick={() => setOpenCommentAnsver(false)} className="setting__comment-item-delete">
                X
              </button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
