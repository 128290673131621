import { CircularProgress } from '@mui/material';
import './Loader.css';

export const Loader = () => {
  return (
    <div className="loader">
      <CircularProgress color="inherit" />
    </div>
  );
};
