import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Context } from '../../Context';
import { api_key_NP, inputs, deliveryBtn, receiver } from './payment-help';
import PaymentProductList from './PaymentProductList/PaymentProductList';
import { Link, useNavigate } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { processProducts } from '../../functions/ProcessProducts';
import { Loader } from '../../components/Loader/Loader';

import './Payment.css';
import Views from '../../components/Views/Views';

export default function Payment() {
  const { sum, setSum, busketNumber, data, userData, user, isAuthenticated, setShowModal } = useContext(Context);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    lastName: '',
    firstName: '',
    phone: '',
    email: '',
    comment: '',
  });
  const [recipientFormData, setRecipientFormData] = useState({
    receivefirstName: '',
    receiverSecondName: '',
    receiverPhone: '',
  });

  // const [wfpData, setWfpData] = useState(null);
  // const [formHTML, setFormHTML] = useState(''); //запис строки юрл для переходу на wfp

  const [editFlag, setEditFlag] = useState(false);
  const [inputFlag, setInputFlag] = useState(false);

  const [itemList, setItemList] = useState(false);

  const [currentDate, setCurrentDate] = useState('');
  const [stringDate, setStringDate] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  // NP state
  const [paymentType, setPaymentType] = useState(2);
  const [city, setCity] = useState('');
  const [cities, setCities] = useState([]);
  const [warehouse, setWarehouse] = useState('');
  const [warehouses, setWarehouses] = useState([]);
  // eslint-disable-next-line
  const [selectedCityRef, setSelectedCityRef] = useState('');
  const [allWarehouses, setAllWarehouses] = useState([]);

  // -------------------------запис данних в поля-------------------------
  useEffect(() => {
    if (userData) {
      const { firstName, secondName, email, phone } = userData;
      setFormData({
        lastName: secondName,
        firstName: firstName,
        phone: phone,
        email: email,
      });
      // setRecipientFormData({
      //   receiverSecondName: secondName,
      //   receivefirstName: firstName,
      //   receiverPhone: phone,
      // });
    }
  }, [userData]);

  // -------------------------формуємо айді з дати-------------------------
  useEffect(() => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const day = now.getDate();
    const hours = now.getHours();
    const minutes = now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes();
    const seconds = now.getSeconds() < 10 ? `0${now.getSeconds()}` : now.getSeconds();
    const paddedMonth = month < 10 ? `0${month}` : month;
    const paddedDay = day < 10 ? `0${day}` : day;

    setCurrentDate(`${month}_${day}_${hours}:${minutes}:${seconds}`);
    setStringDate(`${year}${paddedMonth}${paddedDay}`);
  }, []);

  //---------------------------------------логика отображенния товаров-------------------------------------------

  useEffect(() => {
    const localId = localStorage.getItem('military');
    if (busketNumber.length === 0) {
      navigate('/');
    } else {
      const { updatedProducts, total } = processProducts(data, localId, userData);
      setItemList(updatedProducts);
      setSum(total);
    }
    // eslint-disable-next-line
  }, [data, busketNumber]);

  //-----------------------------------------NP API-------------------------------------------
  useEffect(() => {
    setWarehouse('');
    setWarehouses([]);
  }, [city]);

  const handleCityChange = async (e) => {
    const query = e.target.value;
    setCity(query);

    if (query.length > 1) {
      const response = await axios.post('https://api.novaposhta.ua/v2.0/json/', {
        apiKey: api_key_NP,
        modelName: 'Address',
        calledMethod: 'getCities',
        methodProperties: {
          FindByString: query,
        },
      });

      setCities(response.data.data);
    } else {
      setCities([]);
    }
  };

  const handleCitySelect = (city) => {
    setCity(city.Description);
    setSelectedCityRef(city.Ref);
    setCities([]);
    fetchWarehouses(city.Ref);
  };

  const fetchWarehouses = async (cityRef) => {
    const response = await axios.post('https://api.novaposhta.ua/v2.0/json/', {
      apiKey: api_key_NP,
      modelName: 'Address',
      calledMethod: 'getWarehouses',
      methodProperties: {
        CityRef: cityRef,
      },
    });

    setWarehouses(response.data.data);
    setAllWarehouses(response.data.data); // Зберігаємо всі відділення
  };

  const filterWarehouses = (inputText) => {
    const filtered = allWarehouses.filter((warehouse) => warehouse.Description.toLowerCase().includes(inputText.toLowerCase()));
    setWarehouses(filtered);
  };

  const handleWarehouseSelect = (warehouse) => {
    setWarehouse(warehouse.Description);
    setWarehouses([]);
  };

  //-------------------------------------------------------------------------------

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleRecipientChange = (e) => {
    const { name, value } = e.target;
    setRecipientFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleEditChange = () => {
    setEditFlag(!editFlag);
  };

  useEffect(() => {
    if (!editFlag && userData) {
      const { firstName, secondName, phone } = userData;
      setRecipientFormData({
        receiverSecondName: secondName,
        receivefirstName: firstName,
        receiverPhone: phone,
      });
    } else {
      setRecipientFormData({
        receiverSecondName: '',
        receivefirstName: '',
        receiverPhone: '',
      });
    }
  }, [editFlag, userData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.lastName && formData.firstName && formData.phone && city && warehouse && paymentType) {
      setInputFlag(false);
    } else {
      setInputFlag(true);
      return;
    }
    setIsLoading(true);
    const filteredItemList = itemList.filter((item) => item && Object.keys(item).length > 0);

    const updatedItemList = filteredItemList.map((item) => {
      const itemPrice = userData?.opt ? Math.floor(item.price * (1 - userData.opt / 100)) : item.priceSale ? Math.floor(item.price * (1 - item.priceSale / 100)) : item.price;

      return {
        ...item, // зберігаємо всі інші властивості об'єкта
        price: itemPrice, // оновлюємо властивість price
      };
    });

    const newOrder = {
      orderId: currentDate,
      date: stringDate,
      items: updatedItemList,
    };

    if (isAuthenticated) {
      const uid = user.uid;
      const userOrders = userData.orders || [];
      const updatedUserOrders = [...userOrders, newOrder];
      const newUserData = { ...userData, orders: updatedUserOrders };
      try {
        await setDoc(doc(db, 'users', uid), newUserData);
      } catch (error) {
        console.error('Error writing document: ', error);
      }
    }

    localStorage.removeItem('military');

    const telegramData = formedTelegramData();
    await axios.post('https://military-backend.onrender.com/telegram-message', telegramData);
    setTimeout(() => {
      window.location.href = '/finish-order';
      setIsLoading(false);
    }, 200);
  };

  const formedTelegramData = () => {
    let productList = [];
    let itemPrice;
    if (data && itemList) {
      itemList.forEach((item) => {
        itemPrice = userData?.opt ? Math.floor(item.price * (1 - userData.opt / 100)) : item.priceSale ? Math.floor(item.price * (1 - item.priceSale / 100)) : item.price;
        productList.push(
          `${productList.length + 1}й. арт: ${item.article}, кількість: ${item.quantity}, назва: ${item.title}, колір: ${item.color}${
            item.size ? `, розмір: ${item.size}` : ''
          }, ціна: ${itemPrice} грн`,
        );
      });
    }

    const productNames = productList.join('\n');

    // const payment = paymentType === 1 ? 'Оплата онлайн' : 'Наложений платіж';

    return {
      'Номер замовлення': currentDate,
      // 'Метод оплати': payment,
      // 'Статус оплати': 'непідтверджено',
      'Загальна сума': `${sum} грн`,
      '-=-=-=-=-=-=-': '=-=-=-=-=-=-=-',
      Користувач: formData.lastName + ' ' + formData.firstName,
      Телефон: formData.phone,
      email: formData.email,
      '=-=-=-=-=-=-': '=-=-=-=-=-=-',
      Отримувач: recipientFormData.receivefirstName + ' ' + recipientFormData.receiverSecondName,
      'Телефон отримувача': recipientFormData.receiverPhone,
      'Адреса доставки': city + ', ' + warehouse,
      '-=-=-=-=-=-=': '-=-=-=-=-=-',
      Товар: productNames,
      'Коментар до замовлення': formData.comment,
    };
  };

  // --------------------------data and api wfp--------------------------------

  // const apiWayforpay = async (data) => {
  //   console.log('wfp data==========>', data);
  //   try {
  //     const response = await axios.post('https://military-backend.onrender.com/wayforpay', data);
  //     console.log('response.data', response.data);
  //     setFormHTML(response.data);
  //   } catch (error) {
  //     console.error('Error posting data:', error);
  //   }
  // };

  // useEffect(() => {
  //   let productName = '';
  //   let productCount = 0;
  //   if (itemList) {
  //     itemList.forEach((item, index) => {
  //       const name = item.title;
  //       if (index > 0) {
  //         productName += ', ';
  //       }
  //       productName += name;
  //       productCount++;
  //     });
  //   }

  //   setWfpData({
  //     result_url: 'https://military-65ffe.firebaseapp.com',
  //     amount: sum.toString(),
  //     currency: 'UAH',
  //     orderReference: currentDate,
  //     language: 'ukr',
  //     server_url: 'https://military-backend.onrender.com/wfp-callback',
  //     orderDate: stringDate,
  //     productPrice: sum.toString(),
  //     productName,
  //     productCount: productCount.toString(),
  //   });
  // }, [itemList]);

  const handleAuthClick = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="payment">
        <div className="payment--wrap">
          <div className="payment__left">
            <p className="payment__title">Оформлення замовлення</p>
            <div className="payment__forms-wrap">
              <form className="payment__form">
                <p className="payment__form-title">Ваші контактні данні</p>
                <ul className="payment__form-list">
                  {inputs.map((input) => (
                    <li key={input.name} className="payment__form-item">
                      <input
                        className={`payment__form-input ${inputFlag ? 'payment__form-input--empty' : ''}`}
                        id={input.name}
                        placeholder={input.label}
                        name={input.name}
                        type={input.type}
                        value={formData[input.name]}
                        onChange={handleChange}
                      />
                    </li>
                  ))}
                </ul>
                {!isAuthenticated && (
                  <button className="payment__form-btn" onClick={handleAuthClick} type="button">
                    Увійти в особистий кабінет
                  </button>
                )}
              </form>
              <div className="payment__delivery">
                <p className="payment__form-title">Доставка</p>
                <div className="payment__delivery-wrap">
                  <p className="payment__delivery-text--big">Самовивіз з відділення Нової Пошти</p>
                  <p className="payment__delivery-text--small">(оплата за тарифами перевізника)</p>
                  <div className="payment__left-np">
                    <div className="payment__left-np-input-wrap">
                      <input type="text" value={city} onChange={handleCityChange} placeholder="Введіть місто" required className="payment__left-np-input" />
                      {cities.length > 0 && (
                        <div className="payment__np-list--wrap">
                          <ul className="payment__np-list">
                            {cities.map((city) => (
                              <li key={city.Ref} onClick={() => handleCitySelect(city)} className="payment__np-list-item">
                                {city.Description}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                    <div className="payment__left-np-input-wrap">
                      <input
                        type="text"
                        value={warehouse}
                        onChange={(e) => {
                          setWarehouse(e.target.value);
                          filterWarehouses(e.target.value);
                        }}
                        placeholder="Введіть відділення"
                        className="payment__left-np-input"
                        required
                      />
                      {warehouses.length > 0 && (
                        <div className="payment__np-list--wrap">
                          <ul className="payment__np-list payment__np-list--warehouse">
                            {warehouses.map((warehouse) => (
                              <li key={warehouse.Ref} onClick={() => handleWarehouseSelect(warehouse)} className="payment__np-list-item">
                                {warehouse.Description}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="payment__type-wrap">
                <p className="payment__form-title">Оплата</p>
                <ul className="payment__type-list">
                  {deliveryBtn.map(({ text, secondaryText, index }) => {
                    return (
                      <li
                        key={index}
                        className={`payment__type-list-item ${paymentType === index ? 'payment__type-list-item--active' : ''} ${
                          inputFlag && !paymentType ? 'payment__type-list-item--empty' : ''
                        }`}
                        onClick={() => {
                          setPaymentType(index);
                          // apiWayforpay(wfpData);
                        }}
                      >
                        <p className="payment__type-list-item-text--big">{text}</p>
                        <p className="payment__type-list-item-text--small">{secondaryText}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {user && (
                <form className="payment__form">
                  <p className="payment__form-title">
                    Отримувач{' '}
                    <span className="payment__form-title--edit" onClick={handleEditChange}>
                      {!editFlag ? 'Змінити' : 'Я отримувач'}
                    </span>
                  </p>
                  <ul className="payment__form-list">
                    {receiver.map((input) => (
                      <li key={input.name} className="payment__form-item">
                        <input
                          className="payment__form-input"
                          id={input.name}
                          placeholder={input.label}
                          name={input.name}
                          type={input.type}
                          value={recipientFormData[input.name]}
                          onChange={handleRecipientChange}
                          disabled={!editFlag}
                        />
                      </li>
                    ))}
                  </ul>
                </form>
              )}
              <div className="payment__comment-wrap">
                <p className="payment__form-title">Коментарі до замовлення</p>
                <textarea className="payment__comment-textarea" type="text" name="comment" value={formData.comment} onChange={handleChange} placeholder="Ваш коментар" />
              </div>
            </div>
          </div>
          <div className="payment-right">
            <PaymentProductList itemList={itemList} userData={userData} />
            <div className="payment-right-pay--wrap">
              <div className="payment-right-pay-price--wrap">
                <p className="payment-right-pay-price-text">До сплати</p>
                <p className="payment-right-pay-price">{sum} грн</p>
              </div>
              <Link className="payment-right-pay-btn" onClick={handleSubmit}>
                ПІДТВЕРДИТИ ЗАМОВЛЕННЯ
              </Link>
              <p className="payment-right-pay-text">
                Підтверджуючи замовлення, я приймаю{' '}
                <Link to={'/privacy-policy'} className="payment-right-pay-text-link">
                  умови політики конфіденційності
                </Link>{' '}
                {/* та{' '}
                <Link to={'/'} className="payment-right-pay-text-link">
                  угоди користувача
                </Link> */}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Views />
    </>
  );
}
