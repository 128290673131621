import React, { useContext, useEffect, useState } from 'react';
import Item from '../../components/Item/Item';
import { Context } from '../../Context';
import { pathFilters } from './CategoryInfo';
import { Link, useLocation } from 'react-router-dom';
import { filter } from '../../functions/Filter';
import { useFilterChange } from '../../functions/FilterChange';
import Pagination from '../../components/Pagination/Pagination';
import { Loader } from '../../components/Loader/Loader';
import Views from '../../components/Views/Views';
import { useMediaQuery } from 'react-responsive';
import './Category.css';

export default function Category() {
  const { data, setActiveWindow, setSelectedItems, selectedItems, userData } = useContext(Context);

  const location = useLocation();

  const itemsPerPage = 12;

  const isIphone = useMediaQuery({ maxWidth: 576 });

  // функция для саписи фильтров
  const handleFilterChange = useFilterChange();

  const queryParams = new URLSearchParams(location.search);

  // записываем товары после фильтра
  const [list, setList] = useState(false);

  // имя страницы
  const [title, setTitle] = useState(true);

  // большой фильтр список
  const [filterMainList, setFilterMainList] = useState(false);

  // сбрасываем все и записываем новое
  const handleChange = (value) => {
    setSelectedItems({});
    handleFilterChange(true, 'category', value);
  };

  useEffect(() => {
    if (data && location) {
      const selectedFilter = {
        color: [],
        category: ['всі'],
        subCategories: [],
        size: [],
        page: parseInt(queryParams.get('page'), 10) || 1,
        number: parseInt(queryParams.get('number'), 10) || selectedItems.number,
      };

      // Логика для получения из параметров и установки новых выбранных элементов
      ['color', 'category', 'size', 'subCategories'].forEach((parameter) => {
        const value = queryParams.get(parameter);
        if (value !== null) {
          selectedFilter[parameter] = value.split(',');
        }
      });

      setSelectedItems(selectedFilter);

      // Логика для фильтрации продуктов и установки списка
      let { collection, title, newSale, mainFilter, bestsellers } = pathFilters[location.pathname];

      setFilterMainList(mainFilter);

      const filters = {
        collection,
        newSale,
        category: false,
        subCategories: false,
        bestsellers,
      };

      let filteredProducts = data;

      if (selectedFilter.category[0] !== 'всі') {
        filters.category = selectedFilter.category;
      } else {
        filters.category = false;
      }

      if (selectedItems.number > 0) {
        filteredProducts = filteredProducts.filter((product) => {
          const productPrice =
            product.priceSale && !userData?.opt && product.priceSale !== ''
              ? Math.floor(product.price * (1 - product.priceSale / 100))
              : userData?.opt
              ? Math.floor(product.price * (1 - userData.opt / 100))
              : product.price;
          return parseInt(productPrice) < parseInt(selectedItems.number);
        });
      }

      if (selectedFilter.subCategories.length > 0) {
        filters.subCategories = selectedFilter.subCategories;
      }

      if (selectedFilter.color.length > 0) {
        filters.color = selectedFilter.color;
      }

      filteredProducts = filter(filteredProducts, filters);

      const visibleProducts = filteredProducts.filter((product) => !product.hide);

      setList(visibleProducts);
      setTitle(title);
    }
    // eslint-disable-next-line
  }, [data, location]);

  const startIndex = (selectedItems.page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (
    <>
      {data ? (
        <div name="category" className="category">
          <div className="category__wrapper">
            <div className="category__buttons">
              <ul className="category__buttons-links">
                <li>
                  <Link to={'/'}>Головна</Link>
                </li>
                <li>/</li>
                <li>{title}</li>
              </ul>

              <button onClick={() => setActiveWindow('filter')} className="category__buttons-filter">
                <div className="category__buttons-filter-text">Фільтр</div>
                <div className="category__buttons-filter-icon">
                  <img src="/icon/filter.svg" alt="filter" />
                </div>
              </button>
            </div>
          </div>

          {filterMainList && (
            <div className="category__wrapper">
              {!isIphone ? (
                <ul className="category__filter">
                  {filterMainList.map((item, index) => (
                    <li
                      onClick={() => handleChange(item.value)}
                      key={index}
                      className={`category__filter-item ${item.value === selectedItems.category[0] && 'category__filter-item-active'}`}
                    >
                      {item.label}
                    </li>
                  ))}
                </ul>
              ) : (
                <>
                  <select className="category__filter-mobile" onChange={(e) => handleChange(e.target.value)} value={selectedItems.category[0] || ''}>
                    {filterMainList.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                  <div className="category__filter-mobile-arrow">
                    <img className="img-contain" src="./icon/arrow-green.svg" alt="arrow" />
                  </div>
                </>
              )}
            </div>
          )}
          <ul className="category__item-list">
            {list.length > 0 ? (
              list.slice(startIndex, endIndex).map((item, index) => {
                return <Item item={item} key={index} userData={userData} />; // Отображение товаров
              })
            ) : (
              <div className="category__item-none">Товарів немає ...</div> // Если товаров нет
            )}
          </ul>

          {list.length > 12 && <Pagination items={list} itemsPerPage={itemsPerPage} mainPages={selectedItems.page} />}

          <Views />
        </div>
      ) : (
        <div className="category__loader">
          <Loader />
        </div>
      )}
    </>
  );
}
