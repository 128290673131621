import React from 'react';
import './Contacts.css';

// Массив объектов с информацией о магазинах
const stores = [
  {
    city: 'Запоріжжя',
    address: 'пр. Соборний 172',
    phone: '+380953163015',
    phoneTitle: '+38 (095) 316-30-15',
    hours: 'Пн-Сб: 09:00 - 19:00\nНд: 10:00 - 18:00',
    mapSrc:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2677.8995272630455!2d35.132667676365315!3d47.841539771212304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40dc67efe8a6a097%3A0x278d03c9b5c4dfcd!2z0L_RgNC-0YHQvy4g0KHQvtCx0L7RgNC90YvQuSwgMTcyLCDQl9Cw0L_QvtGA0L7QttGM0LUsINCX0LDQv9C-0YDQvtC20YHQutCw0Y8g0L7QsdC70LDRgdGC0YwsIDY5MDAw!5e0!3m2!1sru!2sua!4v1726572205858!5m2!1sru!2sua',
  },
  {
    city: 'Запоріжжя',
    address: 'вул. Космічна 121-Б',
    phone: '+380662896781',
    phoneTitle: '+38 (066) 289-67-81',
    hours: 'Пн-Сб: 09:00 - 19:00\nНд: 10:00 - 18:00',
    mapSrc:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2680.630939074424!2d35.2186600763623!3d47.78860397120715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40dc5e9433c6eef9%3A0xc685c38ff086b7d0!2z0JrQvtGB0LzQuNGH0LXRgdC60LDRjyDRg9C7LiwgMTIx0JEsINCX0LDQv9C-0YDQvtC20YzQtSwg0JfQsNC_0L7RgNC-0LbRgdC60LDRjyDQvtCx0LvQsNGB0YLRjCwgNjkwMDA!5e0!3m2!1sru!2sua!4v1726572282988!5m2!1sru!2sua',
  },
  {
    city: 'Запоріжжя',
    address: 'пр. Соборний 26',
    phone: '+380662896781',
    phoneTitle: '+38 (066) 289-67-81',
    hours: 'Пн-Сб: 09:00 - 19:00\nНд: 10:00 - 18:00',
    mapSrc:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2679.584537297909!2d35.18370067621368!3d47.808888873986994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40dc5e7192a062d3%3A0xe8bcc7abce39e16e!2z0L_RgNC-0YHQvy4g0KHQvtCx0L7RgNC90YvQuSwgMjYsINCX0LDQv9C-0YDQvtC20YzQtSwg0JfQsNC_0L7RgNC-0LbRgdC60LDRjyDQvtCx0LvQsNGB0YLRjCwgNjkwMDA!5e0!3m2!1suk!2sua&hl=uk',
  },
  {
    city: 'Київ',
    address: 'проспект Оболонський 51 В',
    phone: '+380958733373',
    phoneTitle: '+38 (095) 873-33-73',
    hours: 'Пн-Пт: 10:00 - 19:00\nСб: 09:00 - 19:00\nНд: 10:00 - 18:00',
    mapSrc:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2536.5193661895546!2d30.496334976363972!3d50.5245091820288!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4d2441f18974d%3A0x5f3aea712e71fce8!2z0J7QsdC-0LvQvtC90YHQutC40Lkg0L_RgNC-0YHQvy4sIDUx0JIsINCa0LjQtdCyLCAwMjAwMA!5e0!3m2!1suk!2sua!4v1719743384227!5m2!1suk!2sua',
  },
  {
    city: 'смт. Покровське',
    address: 'вул. Горького, 62',
    phone: '+380953163015',
    phoneTitle: '+38 (095) 316-30-15',
    hours: 'Пн-Пт: 10:00 - 19:00\nСб: 09:00 - 19:00\nНд: 10:00 - 18:00',
    mapSrc:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2670.5049931734748!2d36.22611327637364!3d47.98462707122716!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40ddd14dda9f56eb%3A0x6bc1a83edd33f70b!2z0JLRg9C70LjRhtGPINCT0L7RgNGM0LrQvtCz0L4sIDYyLCDQn9C-0LrRgNC-0LLRgdGM0LrQtSwg0JTQvdGW0L_RgNC-0L_QtdGC0YDQvtCy0YHRjNC60LAg0L7QsdC70LDRgdGC0YwsIDUzNjAx!5e0!3m2!1sru!2sua!4v1726572371255!5m2!1sru!2sua',
  },
  {
    city: 'с.Таврійське (Запорізька область)',
    address: 'вул. Поштова 28',
    phone: '+380953163015',
    phoneTitle: '+38 (095) 316-30-15',
    hours: 'Пн-Пт: 10:00 - 19:00\nСб: 09:00 - 19:00\nНд: 10:00 - 18:00',
    mapSrc:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2679.3504182383153!2d35.176591076363664!3d47.81342647120957!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40dc5e0cdbd273d3%3A0x86cf60c4ae2b8b88!2z0LLRg9C70LjRhtGPINCf0L7RiNGC0L7QstCwLCAyOCwg0JfQsNC_0L7RgNGW0LbQttGPLCDQl9Cw0L_QvtGA0ZbQt9GM0LrQsCDQvtCx0LvQsNGB0YLRjCwgNjkwNjE!5e0!3m2!1sru!2sua!4v1726579081936!5m2!1sru!2sua',
  },
  {
    city: 'с. Комар',
    address: 'вул. Шкільна 38',
    phone: '+380953163015',
    phoneTitle: '+38 (095) 316-30-15',
    hours: 'Пн-Пт: 10:00 - 19:00\nСб: 09:00 - 19:00\nНд: 10:00 - 18:00',
    mapSrc:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2670.7516905342254!2d36.75099877637338!3d47.979858571226494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40de0c9d75ba99cf%3A0xc675750b195ae0be!2z0KjQutGW0LvRjNC90LAsINCa0L7QvNCw0YAsINCU0L7QvdC10YbRjNC60LAg0L7QsdC70LDRgdGC0YwsIDg1NTIw!5e0!3m2!1sru!2sua!4v1726578920381!5m2!1sru!2sua',
  },
];

export default function Contacts() {
  return (
    <div className="contacts">
      <h1 className="contacts__title">Адреси наших магазинів</h1>
      <div className="contacts__wrapper">
        {stores.map((store, index) => (
          <div className="contacts__wrapper-item" key={index}>
            <div className="contacts__wrapper-item-info">
              <h2 className="contacts__wrapper-item-info-title">{store.city}</h2>
              <div className="contacts__wrapper-item-info-wrapper">
                <div className="contacts__wrapper-item-info-wrapper-info">
                  <h3 className="contacts__wrapper-item-info-wrapper-info-title">{store.address}</h3>
                  <a className="contacts__wrapper-item-info-wrapper-info-link" href={`tel:${store.phone}`}>
                    {store.phoneTitle}
                  </a>
                </div>
                <div className="contacts__wrapper-item-info-wrapper-time">
                  <p>{store.hours}</p>
                </div>
              </div>
            </div>
            <div className="contacts__wrapper-item-map">
              <iframe
                title={`${store.city} Store Location`}
                className="contacts__wrapper-item-map"
                src={store.mapSrc}
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
