import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';

export const firebaseConfig = {
  apiKey: "AIzaSyBUxQOclC9vM8__LmOpHu_7lBg7B3Bwsgo",
  authDomain: "military-65ffe.firebaseapp.com",
  projectId: "military-65ffe",
  storageBucket: "military-65ffe.appspot.com",
  messagingSenderId: "823439925146",
  appId: "1:823439925146:web:f3f6b3d6f6dbe7aee42423",
  measurementId: "G-QR4MVTTRZ6"
};


const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage();
export const auth = getAuth(app);
