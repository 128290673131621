import React, { useState, useEffect } from 'react';
import { useFilterChange } from '../../functions/FilterChange';
import './Pagination.css';
import { Link } from 'react-scroll';

const Pagination = ({ items, itemsPerPage, mainPages }) => {
  const [totalPages, setTotalPages] = useState(1);

  // Функция для записи фильтров
  const handleFilterChange = useFilterChange();

  useEffect(() => {
    setTotalPages(Math.ceil(items.length / itemsPerPage));
  }, [items, itemsPerPage]);

  const handlePageChange = (pageNumber) => {
    handleFilterChange(true, 'page', pageNumber);
  };

  return (
    <div className="pagination">
      {Array.from({ length: totalPages }, (_, index) => (
        <Link
          to="category"
          smooth={true}
          duration={500}
          className={mainPages === index + 1 ? 'pagination-active' : ''}
          key={index}
          onClick={() => handlePageChange(index + 1)}
        >
          {index + 1}
        </Link>
      ))}
    </div>
  );
};

export default Pagination;
