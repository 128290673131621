import React from 'react';
import { Link } from 'react-router-dom';
import Views from '../../components/Views/Views';
import './FinishOrder.css';

export default function FinishOrder() {
  return (
    <>
      <div className="finish-order">
        <p className="finish-order__title">Ваше замовленя прийнято!</p>
        <p className="finish-order__text">Зачекайте, будь ласка, поки наш менеджер зв’яжеться з вами для підтверження вашого замовлення.</p>
        <Link to={'/'} className="finish-order__btn">
          Перейти на Головну
        </Link>
      </div>
      <Views />
    </>
  );
}
